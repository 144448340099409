@import '../../styles/variables';

.background {
    width: 100%;
    background-color: $color-white;
    height: 100vh;
    z-index: 88;
    position: relative;
}

.menuScroll {
    position: relative;

    &::after {
        content: "";
        background-image: linear-gradient(0deg, rgba($color-white,1) 0 50%, rgba($color-white, 0));
        position: absolute;
        bottom: -8px;
        left: 0;
        right: 0;
        height: 30px;
        z-index: 100;
    }
}

ul.mainMenu {
    padding: 70px 0 30px;
    margin: 0;
    position: relative; /*absolute*/
    top: 0;
    bottom: 0;
    width: 100%;
    list-style: none;
    background-color: $color-white;
    z-index: 90;

    li {
        padding: 5px 40px;
        color: $color-black;
        text-align: left;
        line-height: 25px;

        a,
        .subMenu {
            font-size: 22px;
            font-weight: 600;
            color: $color-primary;
            text-decoration: none;
        }

        .PTmenuImg {
            width: 30px;
            line-height: 40px;
            margin-left: 10px;
            margin-top: -8px;
        }

        ul {
            list-style: none;
            padding-left:10px;

            li {
                font-size:15px;
                padding:5px 10px;
                line-height: 20px;

                a {
                    font-size: 18px;
                    font-weight: normal;
                    color: $color-primary;
                }
            }
        }
    }
}
