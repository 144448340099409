$baseurl: './../fonts';

@font-face {
    font-family: 'avenir';
    src: url('#{$baseurl}/avenir-webfont.woff2') format('woff2'), url('#{$baseurl}/avenir-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'avenir';
    src: url('#{$baseurl}/avenir-bold-webfont.woff2') format('woff2'), url('#{$baseurl}/avenir-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'avenir';
    src: url('#{$baseurl}/avenir-light-webfont.woff2') format('woff2'), url('#{$baseurl}/avenir-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'avenir';
    src: url('#{$baseurl}/avenir-medium-webfont.woff2') format('woff2'), url('#{$baseurl}/avenir-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'avenir';
    src: url('#{$baseurl}/avenirLTStd-lightOblique.woff2') format('woff2'),
    url('#{$baseurl}/avenirLTStd-lightOblique.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'avenir';
    src: url('#{$baseurl}/avenirLTStd-medium.woff2') format('woff2'),
    url('#{$baseurl}/avenirLTStd-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'avenir';
    src: url('#{$baseurl}/avenirLTStd-heavy.woff2') format('woff2'),
    url('#{$baseurl}/avenirLTStd-heavy.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'avenir';
    src: url('#{$baseurl}/avenirLTStd-mediumOblique.woff2') format('woff2'),
    url('#{$baseurl}/avenirLTStd-mediumOblique.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'avenir';
    src: url('#{$baseurl}/avenirLTStd-heavyOblique.woff2') format('woff2'),
    url('#{$baseurl}/avenirLTStd-heavyOblique.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'avenir';
    src: url('#{$baseurl}/avenirLTStd-light.woff2') format('woff2'),
    url('#{$baseurl}/avenirLTStd-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'avenirLtdBook';
    src: url('#{$baseurl}/avenirLTStd-book.woff2') format('woff2'),
    url('#{$baseurl}/avenirLTStd-book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
// @font-face {
//     font-family: 'avenir';
//     src: url('#{$baseurl}/avenir-book.woff2') format('woff2'),
//     url('#{$baseurl}/avenir-book.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'avenir';
//     src: url('#{$baseurl}/avenirLTStd-book.woff2') format('woff2'),
//     url('#{$baseurl}/avenirLTStd-book.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'avenir';
//     src: url('#{$baseurl}/avenirLTStd-bookOblique.woff2') format('woff2'),
//     url('#{$baseurl}/avenirLTStd-bookOblique.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

