@import "./variables";
@import "./common-imports";

.modalContainer {
    .modal-backdrop {
        position: absolute;
    }
    .modal {
        position: absolute;

        .modal-content {
            border-radius: 10px;
            margin: 0 10px;

            .modal-header {
                background: $color-primary;                   

                .closeIcon {
                    margin: 8px 0;
                    cursor: pointer;
                    color: #fff !important;
                    font-size: 20px;
                }
                .modal-title {
                    color: $color-white; 
                    width: 100%;
                    text-align: center;
                }
            }

            .modal-body {
                font-size: 18px;
            }
        }

        .modal-footer {
            border: 0px;
            .actionButton {                
                border: 0px;
                background: none;
                color: #353D56;
                font-size: 20px;

                .buttonIcon {                    
                    background: rgba(236, 105, 80, 1);
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    padding: 5px;
                    color: $color-white;
                    position: relative;
                }
            }
        }
    }
}