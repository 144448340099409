@import './../styles/variables';

.wrapper {
    padding: 15px;
}

.additionalHeader {
    background: $color-primary;
    padding:20px;
}

.content ,.test123{
    display: none;
}
