@import '../../styles/variables';

.monthText{
    text-align: center;
    font-weight: normal;
    font-variant: normal;
    font-style: normal;
    font-family: Avenir;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.mbscCalendarMarks{
    border-radius: 0.375em;
    height: 0.375em;
    margin: 0 0.0625em;
    width: 0.375em;
}

.mbscCalendarDot {
    border-radius: 0.375em;
    height: 0.375em;
    margin: 0 0.0625em;
    width: 0.375em;
    background-color: #353D56;
}
.startDiv{
    flex-direction: row;
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 10px
}
.startDot{
    display: block;
    width:8px;
    height: 8px;
    border-radius: 50%;
    background-color: #EC6950;
    margin: 1px;
    opacity : 100%;
}
.startDot1{
    display: block;
    width:8px;
    height: 8px;
    border-radius: 50%;
    background-color: #6AB4BD;
    margin: 1px;
    opacity : 100%;
    // position: absolute;
}
.startDot2{
    display: block;
    width:8px;
    height: 8px;
    border-radius: 50%;
    background-color: #8C95B6;
    margin: 1px;
    opacity : 100%;
    // position: absolute;
}
.startTask{
    width:4px;
    height: 4px;
    border-radius: 50%;
    background-color: red;
    // text-align: center;
    // align-items: center;
    // position: absolute;
    // margin-left: 4.2%
}
.sticky {
    position: relative;
    align-items: center;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
}

.currentMonthHeader {
    position: relative;
    width: 100%;
    background: #353D56;
    text-align: center;
}
.periodMonthHeader {
    background:  #353D56;
}
.taskMonthHeader{
    background:  #353D56;
}
// .header{
//     /*top: 150px;*/
//     // width: 33.3%;
//     background:  #353D56;
//     text-align: center;
// }
// .calendar {
//     display: grid;
//     position: relative;
//     width: 100%;
//     background: var(--neutral-color);
//     border: 1px solid var(--border-color);
// }

.calendar  {
    .header {
        font-size: 18px;
        padding: 2px 0;
        text-align: center;
        background:  #353D56;
        color: $color-white;
        line-height: 27px;
    }
    .days {
        display: flex;
        text-transform: uppercase;
        align-content: center;
        position: sticky;
        width: 100%;
        background: #fff;
        z-index: 9999;
        top: 0;

        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
        font-style: normal;
        letter-spacing: 0;
        opacity: 1;
        text-align: center;
        color: #353D56;
    }
}
.calendarDate {
    // padding: 0px;
    height: 32px;
    width: 32px !important;
    border-radius: 50% !important;
    margin: 0 auto;
    padding-bottom: 11.4285712%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    span {
        width: 55%;
        height: 70%;
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1em;
        line-height: 1;
        color: #373d54;
    }
    &:after {
        content: '';
        display: block;
        border-radius: 4px;
        position: absolute;
        z-index: 1;
        bottom: 14%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 7px;
    }
    &.disabled {
        opacity: 0.5;
    }
    &.currentDate {
        text-align: center;
        border: 2px solid #353D56;
        height: 32px;
        width: 32px !important;
        border-radius: 50% !important;
    }
    &.selected {
        border: 1px solid #353D56;
        background-color: #353D56;
        color: #fff;
    }
    &.bold {
        font-weight: 999;
        font-variant: normal;
        font-style: normal;
        font-size: 16px;
        letter-spacing: 0px;
        opacity: 1;
        filter: brightness(100%);
    }
    &.currDate{
        font-variant: normal;
        font-style: normal;
        font-size: 16px;
        letter-spacing: 0px;
        border: 2px solid #ec6950;
        opacity: 1;
        filter: brightness(100%);
        text-align: center;
        height: 32px;
        width: 32px !important;
        border-radius: 50% !important;
    }
    &.selectCircle{
        span{
            font-weight: 999;
        }
    }
}

.calendar  {
    .header {
        font-size: 18px;
        padding: 2px 0;
        text-align: center;
        background-color: #353D56 ;
        color: $color-white;
        line-height: 27px;
    }
    .days {
        display: flex;
        text-transform: uppercase;
        align-content: center;
        position: sticky;
        width: 100%;
        background: #fff;
        z-index: 9990;
        top: 0;
        font-weight: normal;
        font-style: normal;
        font-size: 12px;
        line-height: 19px;
        font-family: Avenir;
        letter-spacing: 0px;
        opacity: 1;
        text-align: center;
        color: #353D56;
        text-transform: uppercase;
    }

}
.calendar .body .cell {
    position: relative;
    height: 5em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    // cursor: pointer;
    transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
    background: var(--bg-color);
    transition: 0.5s ease-out;
}

.calendar .body .selected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
    border-image-slice: 1;
}
.calendar .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
}
.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
    opacity: 0.05;
    transition: .5s ease-in;
}

.calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);
}

.CalendarDays {
    // padding: 0 2.7rem;
    // margin: 1rem 0;
    padding: 0 2.9rem;
    margin: .8rem 0;
}

@media (max-width:480px){
 .CalendarDays {
     padding: 0px 0.9rem;
    }
}

.dateRange{
    opacity: 0.25 !important;
}
.row {
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    text-align: center;
    font-weight: normal;
    font-variant: normal;
    font-style: normal;
    font-family: Avenir;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #353D56;
    opacity: 1;

}
.col {
    flex-grow: 1;
    flex-basis: 0px;
    text-align: center;
    // max-width: 100%;
    color: $color-primary;
    vertical-align: text-bottom;
}
.cellCol {
    flex-grow: 1;
    flex-basis: 0px;
    text-align: center;
    color: $color-primary;
    vertical-align: text-bottom;
}


.heavyPeriod {
    font: normal normal normal 14px/16px Arial;
    text-decoration: underline;
}

.currentDaySection {
 /*   margin-top: 17%;*/
}

.stickyHeader {
    position: sticky;
    height: 32px;
    z-index: 999;
    top: 27px;
}
