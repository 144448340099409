@import './../../styles/variables';

.pageTitle {
    border-bottom: 1px solid transparent;
    box-shadow: $header-shadow;
    padding: 50px 0 20px;
}

.pageHeading {
    font-size: 1.5rem;
    margin: 0 1.25em;
}

.closeIcon {
    font-size: 20px !important;
    top: 15px;
    right: 15px;
}
