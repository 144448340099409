@import './../styles/variables';


.stickyHeader {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}

.InfoCircle {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 40px;
}

.changePwdContainer {
    padding: 0 30px;

    h4 {
        font-weight: 600;
        font-size: 18px;
        color: $color-primary;
        margin: 20px 0 10px !important;
    }
    .element {
        max-width: 90%;
        margin: 0 auto;
        text-align: center;
        position: relative;

        input {
            width: 100%;
            margin: 20px 0 0;
            border-radius: 50px;
            height: 50px;
            padding: 10px;
            border: 1px solid #BEBEBE;
        }

        &.error {
            input {
                border: 1px solid $color-danger;
            }
        }
    }

    .cancelButton {
        background-color: $color-white;
        border: 1px solid #BEBEBE;
        border-radius: 50px;
        min-width: 130px;
        padding: 10px;
        margin: 40px 20px 20px 0;
    }

    .saveButton {
        background-color: $color-danger;
        color: $color-white;
        border: 1px solid $color-danger;
        border-radius: 50px;
        min-width: 130px;
        padding: 10px;
        margin: 40px 20px 20px 0;
    }
}
