@import './../styles/variables';

.progressSection {
    text-align: center;
    max-width: 95%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 15px;

    .progressStrip{
        height: 10px;
        width: 75%;
        display: inline-block;
        background-color: $color-white;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8px;
    }

    .bubbleSection {
        .steps {
            position: relative;
            top: 0;
            width: 25%;

            .bubbleImg {
                width: 27px;
                height: 26px;
                background-color: $color-white;
                border: 3px solid $color-white;
                border-radius: 50%;
                display: block;
                margin: 0 auto;

                svg {
                    display: block;
                }
            }

            label {
                color: $color-white;
                font-size: .8125em;
            }
        }
    }    

    &.threeProgressStrip {
        .progressStrip {
            width: 70%;
            left: 50%;
        }
        .steps {
            width: 33.33%;
        }
    }
}
