@import '../../styles/variables';
@import "../../styles/common-imports";

.pageOverlay {
    background: $bg-backdrop;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
}


@include media-breakpoint-up(sm) {

    .pageOverlay {
        position: absolute;
        left: auto;
        top: auto;
        max-width: 480px;
        max-height: 960px;
        margin: auto;
    }

}
