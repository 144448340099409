@import '../../styles/variables';

.monthDates{
    background-color: rgba(230, 211, 210, 0.258627451);
    opacity: 100%;
    //margin-top: 20px;
}
.monthText{
    text-align: center;
    font-weight: normal;
    font-variant: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.startDiv{
    flex-direction: row;
    display: flex;
    justify-content: center;
}
.startDot{
    justify-content: center;
    align-items: center;
    background: #353D56 0% 0% no-repeat padding-box;
    opacity: 1;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50% !important;
    background-color: #353D56;
    margin: 0px 3px 2px 5px;
    position: absolute;
    z-index: 10;
    top: 25px;
    left: 0;
    right: 0;
    margin: auto;
}

.sticky { 
    position: relative;
    align-items: center;
    background-color: transparent;
    transition: all 0.3s ease-in-out; 
}
.currentMonthHeader {
    position: relative;
    width: 100%; 
    background:  #EC6950;
    text-align: center;
    padding-top: 20px;
    display:flex;
}
.periodMonthHeader {
    background:  #EC6950;
}
.taskMonthHeader{
    background:  #353D56;
}
.header{
    background:  #EC6950;
    text-align: center;
}

.calendar {
    display: grid;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
}

.highLight {
    // background-color: red
    
}
.calendar  {
    .header {
        font-size: 18px;
        padding: 2px 0;
        text-align: center;
        background-color: #EC6950 ;
        color: $color-white;
        line-height: 27px;
    }
    .days {
        display: flex;
        text-transform: uppercase;    
        align-content: center;
        position: sticky;
        width: 100%;
        background: #fff;
        z-index: 9990;
        top: 0;
        
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
        font-style: normal;
        letter-spacing: 0px;
        opacity: 1;
        text-align: center;
        color: #353D56;
        text-transform: uppercase;
    }

    .CalendarDays{        
        .highLight
            {
                span {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
                .clsHighLightBorder {
                    margin-left: 0px !important;
                }
                
            }
    }
}

// .cell
.calendar .body .cell {
    position: relative;
    height: 5em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
    background: var(--bg-color);
    transition: 0.5s ease-out;
}

.calendar .body .selected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
    border-image-slice: 1;
}
.calendar .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
}
// .calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
    opacity: 0.05;
    transition: .5s ease-in;
}

.calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);
}

.CalendarDays {
    padding: 0 10%;
    /*margin: .8rem 0;*/
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-family: Avenir, sans-serif;
    font-weight: 100;

    .currentDate{
        span {
            border: 2px solid #ec6950;
            border-radius: 100%;
        }
    }
    .selectCircle{
              span{
                   border: 2px solid #ec6950;
                   border-radius: 100%;
                   font-weight: 999;
              }
           }
    .calendarDate {
        width: 14.285714%;
        height: 0;
        padding-bottom: 11.4285712%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        span {
            width: 55%;
            height: 70%;
            position: absolute;
            z-index: 2;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1em;
            line-height: 1;
            color: #373d54;
        }

        &:after {
            content: '';
            display: block;
            // border-radius: 4px;
            position: absolute;
            z-index: 1;
            bottom: 14%;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 7px;
        }

        &.flowLight{
            &:after {
                background-color: rgb(249, 209, 202);
            }
        }

        &.flowHeavy {
            &:after {
                background-color: rgb(236, 105, 80);
            }
        }

        &.flowStart {
            &:before {
                content: '';
                width: 7px;
                height: 7px;
                border-radius: 100%;
                position: absolute;
                z-index: 3;
                bottom: 14%;
                left: 50%;
                transform: translateX(-50%);
                background-color: #353D56;
            }
        }

        &.rightBorder {
            &:after {
                border-top-right-radius: 4px !important;
                border-bottom-right-radius: 4px !important;
                width: 100% !important;
                margin-right: 4px;
            }
        }
        
        &.leftBorder {
            &:after {
                border-top-left-radius: 4px  !important;
                border-bottom-left-radius: 4px  !important;
                width: 100% !important;
                margin-left: 4px;
            }
        }

        &.leftBorder.rightBorder {
            &:after {
                margin-left: 0;
                margin-right: 0;
                width: 95% !important;
            }
        }

        &.disabled {
            pointer-events: none;
            span {
                /*color: #9a9ba5;*/
                opacity: 0.5;
            }
        }

        &.bold {
            font-weight: 700;
        }

        &.flowStartProjected {
            &:before {                
                content: '';
                width: 7px;
                height: 7px;
                border-radius: 100%;
                position: absolute;
                z-index: 3;
                bottom: 14%;
                left: 50%;
                transform: translateX(-50%);
                background-color: transparent;
                border: 1px #353D56 solid;
            }
        }
    }
}

.dateRange{
    opacity: 0.25 !important;
}
.row {
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    text-align: center;
    font-weight: normal;
    font-variant: normal;
    font-style: normal;
    // font-family: Avenir;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #353D56;
    opacity: 1;
}
.col {
    flex-grow: 1;
    flex-basis: 0px;
    text-align: center;
    // max-width: 100%;
    color: $color-primary;
    vertical-align: text-bottom;
}
.cellCol {
    flex-grow: 1;
    flex-basis: 0px;
    text-align: center;
    color: $color-primary;
    vertical-align: text-bottom;
}

.heavyPeriod {
    font: normal normal normal 14px/16px Arial;
    text-decoration: underline;
}

.currentDaySection {
 /*   margin-top: 17%;*/    
}

.stickyHeader {
    position: sticky;
    height: 32px;
    z-index: 999;
    top: 27px;
} 

.highLight {
    position: relative;
}

.light {
    &::after {
        content : "";
        display: block;
        margin: 0rem 0px;
        // margin: 0rem 0rem 0rem 1rem;
        border-bottom: 7px solid #EC6950;
        opacity: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}

.rightBorder {
    &::after {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        width:95%;
    }
}

.leftBorder {
    &::after {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
}
.lineWidth{
    &::after{
        width: 50%;
    }
    
}