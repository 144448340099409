@import './../styles/variables';


.notifycontainer {
    height: 100%;

    .notifySection {
        display: flex;
        flex-direction: column;
        height: 100%;

        .messageSection {
            padding: 40px;
            color: #313131;
            font-size: 18px;
            align-self: flex-start;
            flex-grow: 3;


            .buttonIcon {
                width: 30px;
                height: 22px;
                color: #fff;
                position: absolute;
                bottom: 0;
                top: 8px;
                right: 2px;
            }
        }
    }

    .roundedBackgroundButton {
        font-size: 16px;
        margin: 20px 0;
        width: 45%;
        border-radius: 50px;
        background: $color-primary;
        color: $color-white;
        padding: 5px 0;
    }

    .completeButton {
        align-self: flex-end;
        flex-grow: 1;
        text-align: right;
        margin: 0 40px;

        .button {
            background: #ec6950;
            border: 0;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
            height: 35px;
            padding-left: 15px;
            line-height: 30px;

            .buttonIcon {
                width: 20px;
                height: 30px;
                margin-left: 5px;
            }
        }
    }
}


.notifyModalContainer {
    background-color: rgba($color-black, 0.5);
    z-index: 1010;
}

.notifyModal {
    min-height: 170px;
    background-color: #fff;
    left: 20px;
    right: 20px;
    border-radius: 10px;
    border: 0;

    .modalHeader {
        background-color: #353d56;
        padding: 15px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #fff;
        font-size: 22px;
        text-align: center;
        font-weight: bold;
    }

    .modalDetails {
        padding: 10px;
        font-size: 20px;
        text-align: center;
    }

    .modalFooter {
        text-align: center;
        padding: 5px 5px 10px 5px;

        .footerbutton {
            position: relative;
            margin: 10px;
            width: auto;
            padding: 8px 20px;
            background-color: $color-danger !important;
            color: #fff;
            font-size: 20px;
            border: 0px;
            border-radius: 10px;
        }
    }
}
