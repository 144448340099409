@import '../../styles/variables';

.header {
    background-color: $color-white;
    color: $color-primary;
    padding: 7px 15px;
    position: relative;
    z-index: 10;
}
.head{
    z-index: 999;
}
.logo {
    height: 245px;
    width: auto;
    fill: currentColor;
}

.innerHeader {
    position: relative;
    padding: 15px;
    z-index: 10;

    .logo {
        height: 50px;
        width: auto;
        fill: $color-white;
    }
}

.menuIconContainer {
    cursor: pointer;
    background: $color-white;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    text-align: center;

    .menuIcon {
        width: 24px;
        height: auto;
        margin: 12px 0;
        z-index: 999;
        position: relative;
    }

    .crossMenuIcon {
        display: inline-block;
        z-index: 999;
        position: relative;
        background: white;
        border-radius: 50%;
        padding: 1px 10px;
    }
}

.notification {
    position: relative;
    margin-right: 20px;
    cursor: pointer;
}

.counter {
    top: -7px;
    right: -10px;
    position: absolute;
    background-color: $color-danger;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 1px solid $color-white;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    padding-bottom: 1px;
    padding: 2px 3px;
}

.bell {
    width: auto;
    height: 24px;
}

.head {
    z-index: 999;
    position: relative;
}


.notificationModalContainer {
    background-color: rgba($color-black,0.5);
    z-index: 1010;
}

nav.headerNav {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #353D56;
}

.notificationModalContainer {
    background-color: rgba($color-black, 0.5);
    z-index: 1010;
}

.notificationModal {
    min-height: 150px;
    background-color: $color-danger;
    left: 20px;
    right: 20px;
    border-radius: 10px;
    border: 0;
    padding: 10px;
    /*&:after {
        content: "";
        position: absolute;
        bottom: 100%;
        right: 12px;
        margin-left: -5px;
        border-width: 15px;
        border-style: solid;
        border-color: transparent transparent #fff transparent;
    }*/

    .nofityHeader {
        display: inline-block;
        width: 100%;

        .notifyBellModel {
            position: absolute;
            left: 12px;
            width: 30px;
            top: 10px;
        }

        .notifyCrossModel {
            position: absolute;
            right: 12px;
            width: 25px;
            top: 4px;
            color: $color-white;
            font-size: 31px;
            line-height: 33px;
            cursor: pointer;
        }
    }

    .notifyDetails {
        padding: 10px 5px;

        ul {
            padding: 0 15px;
            margin: 0px;

            li {
                list-style: none;
                padding: 0px 0;
                cursor: pointer;

                .notifyCounter {
                    border-radius: 50%;
                    border: 1px solid #fff;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 18px;
                    float: left;
                    vertical-align: top;
                    font-size: 12px;
                    color: $color-white;
                }

                .notifyText {
                    display: inline-block;
                    width: 100%;
                    padding: 12px 0;
                    color: $color-white;
                    cursor: pointer;
                    border-bottom: 1px solid #fff;
                    line-height: 23px;
                    padding: 15px 0;

                    a {
                        color: $color-white;
                    }
                }
            }

            li:last-child {
                .notifyText {
                    border-bottom: 0px;
                }
            }
        }
    }
}
