@import './../styles/variables';

.stickyHeader {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}

.appointmentContainer {

    .appointmentcontent {
        h4 {
            font-size: 28px;
            color: #414042;
            text-align: center;
            max-width: 50%;
            margin: 0 auto;
            padding: 20px 30px 20px;
        }

        .appointInfo {
            margin: 15px 0 0;
            display: inline-block;
            width: 100%;

            label {
                width: 20%;
                float: left;
                color: #858585;
                font-size: 16px;
                text-align: right;
                padding-right: 5px;
            }

            span {
                color: #353D56;
                font-size: 16px;
                font-weight: 600;
                float: left;
            }

            .whereAddress {
                width: 70%;
                display: inline-block;

                address {
                    width: 100%;
                    display: inline-block;
                    margin-bottom: 10px;
                }

                p {
                    margin: 0 0 0 10px;
                    font-weight: normal;
                }
            }

            .whereDoctorAddress {
                width: 70%;
                display: inline-block;

                address {
                    width: 100%;
                    margin: 0;
                    font-weight: bold;

                    p {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }

    .contactCoordinator {
        padding: 30px 70px;
        border-top: 2px solid #ddd;
        width: 90%;
        text-align: center;
        margin: 10px auto;

        span {
            color: $color-primary;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .attachmentLists {
        margin: 70px 0 30px 0;
        padding: 0px 30px 10px;

        .attachmentHeading {
            font-weight: 600;
            font-size: 18px;
            color: $color-primary;
            margin-bottom: 10px;
            display: inline-block;
        }

        ul {
            list-style: none;
            padding-left: 20px;

            li {
                margin: 0 0 10px 0;
                cursor: pointer;

                span {
                    border-bottom: 1px solid $color-primary;
                    color: $color-primary;
                    font-size: 18px;
                    word-break: break-word;
                }
            }
        }
    }

    .taskCompletedButton {
        text-align: center;

        button {
            background: $color-danger;
            padding: 10px 20px;
            color: $color-white;
            border-radius: 50px;
            font-size: 16px;
            margin: 20px 0;
            display: inline-block;
        }
    }

    .interviewFor {
        text-align: center;
        font-size: 18px;
        color: #414042;
        font-weight: 600;
        padding-bottom: 20px;
        border-bottom: 1px solid #707070;
        width: 90%;
        margin: 20px auto;
    }
}


.doctorModalOverlay {
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 999;

    .doctorModalContainer {
        background: #fff;
        position: absolute;
        left: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        margin: 0 auto;
        padding: 10% 0 5% 0;
        text-align: center;

        .doctorModalIcon {
            top: 20px;
            right: 20px;
            width: 25px;
            height: 25px;
            cursor: pointer;
        }

        .content {
            font-size: 16px;
            font-weight: 600;
            color: $color-primary;
            margin: 0 auto;
            width: 100%;
            display: inline-block;
            text-align: center;
            padding: 20px;
        }

        .largeContent {
            font-size: 24px;
            color: $color-primary;
            font-weight: 600;
            display: inline-block;
            width: 100%;
            text-align: center;
            padding-bottom: 10px;
        }
    }
}

.roundedBackgroundButton {
    font-size: 16px;
    margin: 20px 0;
    width: 80%;
    border-radius: 50px;
    background: $color-primary;
    color: $color-white;
    padding: 10px 0;

    &.disabledButton {
        opacity: 0.8;
        cursor: default;
    }
}

.appointmentPhotoIns {
    padding: 20px 10px;
    width: 90%;
    margin: 40px auto 0;
    border-top: 1px solid #707070;
}
