@import './../styles/variables';

.BmiPageTitle {
    color: $color-primary;
    text-align: center;
    font-size: 1.8rem;
    margin: -7px 0px;
}

.BmiContainer {
    padding: 0 20px;
    .BMICalculator {
        background-color: #E1E2E6;
        padding: 50px;
        width: 100%;
        max-width: 480px;
        border-radius: 15px;
        margin:  20px 0;
    }

    .inputBox {
        background-color: $color-white;
        border:2px solid #CCCDD0;
        padding: 10px 5px;
        margin: 10px 0;
    }

    .sectionHead {
        font-size: 20px;
        color: $color-primary;
        font-family: "Avenir";
    }

    .labelHead{
        color: $color-primary;
        font-family: "Avenir";
        opacity: 0.8;
    }
    .BmiCalcButton {
        background-color: $color-danger;
        color: $color-white;
        display: inline-block;
        border: 0px;
        border-radius: 50px;
        width: 100%;
        padding: 15px 20px;
        font-size: 20px;
    }
}

.msgContainer {
    .msgDate {
        color: $messaging-text;
        font-size: 16px;
        font-family: "Avenir";
        margin: 10px 0 20px 0;
        text-align: center;
    }

    .questionContainer {
        margin-bottom: 0px;

        .questionTag{
            background: $messaging-box-bg;
            padding:10px;
            color: $messaging-text;
            font-size: 16px;
            width: 60%;
            display: inline-block;
            position: relative;
            margin-left: 10px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;

            &::after {
                content: "";
                position: absolute;
                top: 5px;
                right: 98%;
                margin-top: -5px;
                border-top: 0px solid transparent;
                border-right: 20px solid $messaging-box-bg;
                border-bottom: 20px solid transparent;
                background: transparent;
                border-left: transparent;
            }
        }
        .questionTime{
            width: 30%;
            padding: 10px;
            display: inline-block;
            vertical-align: top;
            text-align: left;
        }

        .attachment {
            float: left;
            clear: both;
            margin: 10px 0;
            width: auto;
            padding: 5px 10px;
            border-radius: 10px;
            background: $messaging-attachement-bg;
        }
    }

    .answerContainer {
        margin-bottom: 0px;

        .answerTag{
            background: $color-secondary;
            padding:10px;
            color: $color-white;
            font-size: 16px;
            width: 62%;
            display: inline-block;
            position: relative;
            margin-left: 25px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;

            &::after {
                content: "";
                position: absolute;
                top: 5px;
                left: 98%;
                margin-top: -5px;
                border-top: 0px solid transparent;
                border-right: transparent;
                border-bottom: 20px solid transparent;
                background: transparent;
                border-left: 20px solid $color-secondary;
            }
        }
        .answerTime{
            width: 30%;
            padding: 10px;
            display: inline-block;
            vertical-align: top;
            text-align: right;
        }

        .attachment {
            float: right;
            clear: both;
            margin: 5px 0;
            width: auto;
            padding: 5px 10px;
            border-radius: 10px;
            background: $messaging-attachement-bg;
        }
    }

    .messages {
        padding: 10px 10px;
        border-bottom: 3px solid #E9E9E9;
        cursor: pointer;
        &:first-child{
            padding-top: 10px;
        }

        .newMsg {
            width: 5%;
            display: inline-block;
            vertical-align: top;
        }

        .msgContent {
            width: 65%;
            display: inline-block;
            vertical-align: top;
            padding:0 10px;

            span {
                display: inline-block;
                width: 100%;
                &.msgfrom {
                    font-size: 18px;
                }
                &.msgtxt {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp:1; /* number of lines to show */
                            line-clamp:1;
                    -webkit-box-orient: vertical;
                }
                &.subject {
                    font-size: 18px;
                }
                &.subjectnew {
                    font-weight: 600;
                    font-size: 18px;
                }
            }
        }

        .msgDates {
            width: 30%;
            display: inline-block;
            vertical-align: top;
            text-align: right;
            font-size: 17px;

            .msgContentIcon {
                font-size: 22px;
                color: #888;
            }
        }

        .msgNew {
            font-size: 50px;
            color: $color-danger;
            margin-left: -15px;
        }
    }
}
