@import "./variables";

.auth-form {
    input[type="email"],
    input[type="password"],
    input[type="pin"] {
        margin: 5px 0 25px;
        border-radius: 30px;
        border: 1px none;
        font-size: 16px;
        font-weight: 600;
        height: 60px;
        padding-left: 20px;
        color: $color-gray;
    }

    /*.inputControlElement {
        margin-bottom: 25px !important;
    }*/
}


.auth-button {
    text-align: right;

    button {
        background:transparent;
        border: 0;
        text-align: right;
        transition: all 0.3s;
        padding: 10px 0;

        &:hover,
        &:active,
        &:focus {
            background:transparent !important;
            border: 0 !important;
            box-shadow: none !important;
        }

        .btn-icon {
            background: $color-danger;
            height: 40px;
            width: 40px;
            padding: 5px;
            border-radius: 50%;
            text-align: center;
            font-size: 30px;
            line-height: 1;
            cursor: pointer;
            margin-left: 10px;
            transition: margin-left .3s;

            svg {
                display: block;
                position: relative;
                left: 1px;
            }
        }

        &:hover {
            .btn-icon {
                margin-left: 6px;
            }
        }
    }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
