
@import '../../styles/variables';

.wrapper {
    padding: 15px;
}
button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
}
.pageTitleSection {
    border-bottom: 1px solid transparent;
    background: $color-primary;
    box-shadow: 0 4px 6px -6px $color-black;
    padding: 50px 0 20px 0;

    h1 {
        font-size: 1.5rem;
    }

    .closeIcon {
        right: 20px;
        top: 20%;
        color: $color-white;
        font-size: 20px;
    }
}

.submit{
    flex-grow: 1;
    align-items: flex-end;
}

.submitSchedular {
    background: #EC6950 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    right: 0;
    width: 111px;
    height: 35px;
    position: relative;
}

.button{
    background: $color-danger;
    border-radius: 20px;
    height: 35px;
    color: $color-white;
}

.submitButton {
    background:$messaging-input-icon-color;
    border-radius: 20px;
    height: 35px;
    color: $color-white;
}
.submitButton {
    margin-top: 90px;
    &.active {
        background:$color-danger;
      }
}
.buttonIcon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: $color-white;
    position: absolute;
    bottom: 3px;
    right: 0;
}

.datepickerWrap {
    width: 80%;
    max-width: $datepicker-max-width;
}

.datepickerTxt {
    --input-width: 100%;
    width: 100%;
    padding: 10px 0;
    text-align: center;
    position: relative;

    input[type="text"]
    {
        padding: 11px 50px 11px 22px;
        width: var(--input-width);
        border-radius: 50px;
        border: 1px solid $color-primary;
    }

    .calendarIcon {
        font-size: 30px;
        position: absolute;
        right: calc((100% - var(--input-width))/2 + 20px);
        top: 17px;
        cursor: pointer;
    }
}

.classPhotoInst {
    font-size:14px;
}

.textView {
    padding-left: 15px;
    font-weight: bold;
    font-size: 18px;
    color: #353D56;
    width: 100%;
}

.docUL {
    padding-left: 0;
}

.address {
    padding-left: 20px;
    line-height: 1.2;

    .addName {
        font-weight: bold;
        font-size: 16px;
        color: $color-primary;
        font-family: "Avenir";
    }

    .schedulIns {
        font-size: 13px;
        margin: 10px 0;
        width: 98%;
    }
}
.checkAdddress {
    padding-left: 12px;
    width: 90%;

    .addName {
        font-weight: bold;
        font-size: 16px;
        color: $color-primary;
        font-family: "Avenir";
    }

    .schedulIns {
        font-size: 13px;
        margin: 10px 0;
    }
}


.checkbox_formElement label {
    color: #565656;
    font-family: avenirLtdBook;
    font-size: 1em;
    flex-direction: row;
    margin: 15px 20px 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
}

input[type=checkbox],
input[type=radio] {
    appearance: none;
    padding: 0;
    margin: 0;
    border: 1px #da7058 solid;
    width: 1.125em;
    height: 1.125em;
    border-radius: .15em;
    position: relative;
    top: .05em;
    background-color: #fff;
    color: #565656;
}

input[type=checkbox]:checked,
input[type=radio]:checked {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpolyline fill='none' stroke='%23565656' stroke-width='3' stroke-linecap='round' stroke-line-join='round' stroke-miterlimit='10' points='1.87,9.64 6.53,16.25 16.13,1.75 '/%3E%3C/svg%3E%0A");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 80% 80%;
}

.scheduleButton {
    background: $color-primary;
    border-radius: 20px;
    font-size: 16px;
    line-height: 35px;
    height: 35px;
    color: $color-white;
    border: 0;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: white;
    }
}


.schModalContainer {
    background-color: rgba($color-black, 0.5);
    z-index: 1010;
}

.psychModal {
    min-height: 170px;
    background-color: #fff;
    left: 20px;
    right: 20px;
    border-radius: 10px;
    border: 0;

    .modalHeader {
        background-color: #353d56;
        padding: 15px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #fff;
        font-size: 22px;
        text-align: center;
        font-weight: bold;
    }

    .modalDetails {
        padding: 10px;
        font-size: 20px;
        text-align: center;
    }

    .modalFooter {
        text-align: center;
        padding: 5px 5px 10px 5px;

        .footerbutton {
            position: relative;
            margin: 10px;
            width: auto;
            padding: 8px 20px;
            background-color: $color-danger !important;
            color: #fff;
            font-size: 20px;
            border: 0px;
            border-radius: 10px;
        }
    }
}
