@import './../styles/variables';
@import "./../styles/common-imports";

.wrapper {
    padding: 15px;
}

.chooseFileClass {
    background: $color-primary;
    color: $color-white;
    font-size: 1.3rem;
    position: relative;
    text-align: center;
    width: 60%;
    list-style: none;
    padding: 0px;
    margin: 20px auto;
    padding:10px;
    border-radius: 50px;
    cursor: pointer;
}

.inputBox {
    text-align: center;
    position:relative;
    width: 80%;
    display: inline-block;
}

.inputIcon {
    position: absolute;
    background: $messaging-input-icon-color;
    width: 40px;
    height: 40px;
    right: 5px;
    bottom: 67px;
    border-radius: 50px;
    color: $color-white;

    svg {
        width: 86%;
        height: auto;
        transform: translate(-2%, 3%);
    }

    &.active {
        background: $color-secondary;
    }
}

.modalOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: $bg-backdrop;
    z-index: 8;
}

.sendMsg {
    cursor: pointer;
}

.photoModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    width: 50%;
    background: #fff;
    padding: 10px 0;
    font-size: 20px;
    border-radius: 20px;
    overflow: hidden;
}

.spanTextArea {
    display: block;
    width: 100%;
    overflow: hidden;
    resize: none;
    min-height: 50px;
    line-height: 20px;
    border: 1px solid $messaging-box-border;
    font-family: inherit;
    font-size: 16px;
    padding: 10px 50px 10px 10px;
    text-align: left;
    border-radius: 25px;
    color: $messaging-text;
    font-family: "Avenir";
    background: $color-white;
}

.uploadTasks {
    width: 100%;
    list-style: none;
    padding: 0px;
    margin: 0 auto;

    li {
        width: 100%;
        display: inline-block;
        font-size: 20px;
        position: relative;
        text-align: left;
        color: $color-primary;
        text-decoration: none;
        cursor: pointer;
        border-bottom: 1px solid #ddd;
        padding: 0 10px;
        font-family: "Avenir";

        &:last-child {
            border-bottom: 0px;
        }

        .uploadIcon {
            float: right;
            font-size: 27px;
        }
    }
}

.showFileContainer {
    margin-bottom: 40px;
    .fileIcon {
        color: #8C95B6;
        font-size: 60px;
    }

    .cameraPicture {
        width: 100%;
    }
}


button {
    margin-top: 20px;
    font-size: 20px;
    color: $color-primary;
    border: 0px;
    background: transparent;
}

.cancelButtonIcon {
    background: rgba(236, 105, 80, 0.7);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 5px;
    color: $color-white;
    position: relative;
}



.uploadButton {
    margin-top: 20px;
    position: relative;
    float: right;
    button {
        background: #ddd;
        border: 0px;
        color: $color-text-gray;
        font-size: 16px;
        cursor: default !important;
        color: #fff;
        border-radius: 50px;
        span {
            margin: 5px 0 0 15px;
            line-height: 25px;
            padding-top: 2px;
            float: left;
        }
    }

    .buttonIcon {
        width: 40px;
        height: 40px;
        /*border-radius: 50%;*/
        padding: 7px 0;
        color: $color-white;
        position: relative;
        opacity: 0.7;
    }

    &.active {
        button {
            background: $color-danger;
            color: #fff;
            cursor: pointer !important;
        }
        .buttonIcon {
            opacity: 1;
        }
    }
}



.msgContainer {
    /*padding: 10px 20px;
    height: 500px;
    overflow-y: scroll;*/
    .msgDate {
        color: $messaging-text;
        font-size: 16px;
        font-family: "Avenir";
        margin: 10px 0 20px 0;
        text-align: center;
    }

    .questionContainer {
        margin-bottom: 0px;

        .questionTag{
            background: $messaging-box-bg;
            padding:10px;
            color: $messaging-text;
            font-size: 16px;
            width: 60%;
            display: inline-block;
            position: relative;
            margin-left: 10px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;

            &::after {
                content: "";
                position: absolute;
                top: 5px;
                right: 98%;
                margin-top: -5px;
                border-top: 0px solid transparent;
                border-right: 20px solid $messaging-box-bg;
                border-bottom: 20px solid transparent;
                background: transparent;
                border-left: transparent;
              }
        }
        .questionTime{
            width: 30%;
            padding: 10px;
            display: inline-block;
            vertical-align: top;
            text-align: left;
        }

        .attachment {
            float: left;
            clear: both;
            margin: 10px 0;
            width: auto;
            padding: 5px 10px;
            border-radius: 10px;
            background: $messaging-attachement-bg;
        }
    }

    .answerContainer {
        margin-bottom: 0px;

        .answerTag{
            background: $color-secondary;
            padding:10px;
            color: $color-white;
            font-size: 16px;
            width: 60%;
            display: inline-block;
            position: relative;
            margin-left: 37px;
            border-top-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;

            &::after {
                content: "";
                position: absolute;
                top: 5px;
                left: 98%;
                margin-top: -5px;
                border-top: 0px solid transparent;
                border-right: transparent;
                border-bottom: 20px solid transparent;
                background: transparent;
                border-left: 20px solid $color-secondary;
              }
        }
    }

    .attachmentContainer {
        .answerTag {
            background: none;
            color: $color-text;
            &::after {
                display: none;
            }
        }
        .questionTag {
            background: none;
            color: $color-text;
            &::after {
                display: none;
            }
        }
    }
    .answerTime{
        width: 30%;
        padding: 10px;
        display: inline-block;
        vertical-align: top;
        text-align: right;
    }

    .attachment {
        float: right;
        clear: both;
        margin: 5px 0;
        width: auto;
        padding: 5px 10px;
        border-radius: 10px;
        background: $messaging-attachement-bg;
    }

    .messages {
        padding: 10px 10px;
        cursor: pointer;
        &:first-child{
            padding-top: 10px;
        }

        .newMsg {
            width: 5%;
            display: inline-block;
            vertical-align: top;
        }

        .msgContent {
            width: 65%;
            display: inline-block;
            vertical-align: top;
            padding:0 10px;

            span {
                display: inline-block;
                width: 100%;
                &.msgfrom {
                    font-size: 18px;
                }
                &.msgtxt {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* number of lines to show */
                            line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                &.subject {
                    font-size: 18px;
                }
                &.subjectnew {
                    font-weight: 600;
                    font-size: 18px;
                }
            }
        }

        .msgDates {
            width: 30%;
            display: inline-block;
            vertical-align: top;
            text-align: right;
            font-size: 17px;

            .msgContentIcon {
                font-size: 22px;
                color: #888;
            }
        }

        .msgNew {
            font-size: 50px;
            color: $color-danger;
            margin-left: -15px;
        }
    }
}

.cameraContainer {
    position: absolute;
    width: 98%;
    background: #fff;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    text-align: center;
}

.stickyHeader {
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 999;
    box-shadow: $header-shadow;
}

.uploadFileList {
    padding: 0px;
    max-width: 80%;
    margin: 0 auto;
    text-align: left;

    li {
        list-style: none;
        font-size: 18px;
        color: #414042;
        text-align: left;
        width: 100%;
        display: inline-block;
        margin: 10px 0;

        .filename {
            display: inline-block;
            width: 80%;
            word-wrap: break-word;
        }

        .fileSize {
            display: inline-block;
            width: 25%;
            vertical-align: top;
        }
    }
}

.pageOverlay {
    background: $bg-backdrop;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 9999;
}

.fileErrorModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 80%;
    padding: 20px;
    z-index: 33;
    text-align: center;

    .errorTitle {
        width: 100%;
        color: $color-danger;
        font-size: 18px;
        text-align: center;
        display: inline-block;
        margin-bottom: 10px;
        font-weight: 600;
    }

    button {
        padding: 5px 30px;
        background: #353D56;
        border-radius: 50px;
        color: #fff;
        vertical-align: middle;
    }
}

.fileErrorOverlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $bg-backdrop; /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}

@include media-breakpoint-up(sm) {

    .pageOverlay {
        position: absolute;
        left: auto;
        top: auto;
        max-width: 480px;
        max-height: 960px;
        margin: auto;
    }

}
