@import './../../styles/variables';

.loginFrmValidationError {
    display: inline-block;
    width: 100%;
    margin: 0 0 10px;
    padding: 10px 5px;

    .errMsg {
        display: inline-block;
        width: 80%;
        color: $color-white;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        margin: 5px 0;
        vertical-align: middle;
    }

    .exlIcon {
        display: inline-block;
        font-size: 40px;
        line-height: 40px;
        font-weight: 600;
        color: $color-white;
        margin-right: 8px;
        background: $color-danger;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        text-align: center;
        vertical-align: middle;
    }
}
