@import './../styles/variables';


.iframeBackButton {
    background-color: $color-primary;
    height: 50px;
}

.iframeDocu {
    width: 100%;
    height: 100%;
}

.notifycontainer {
    height: 100%;

    .notifySection {
        display: flex;
        flex-direction: column;
        height: 100%;

        .messageSection {
            padding: 40px;
            color: #313131;
            font-size: 18px;
            align-self: flex-start;
            width: 100%;


            .buttonIcon {
                width: 30px;
                height: 22px;
                color: #fff;
                position: absolute;
                bottom: 0;
                top: 8px;
                right: 2px;
            }
        }
    }

    .roundedBackgroundButton {
        font-size: 16px;
        margin: 20px 0;
        width: 45%;
        border-radius: 50px;
        background: $color-primary;
        color: $color-white;
        padding: 5px 0;
        display: inline-block;
        text-decoration: none;
    }

    .deactiveButton {
        opacity: 0.5;
        cursor: default;
    }

    .completeButton {
        align-self: flex-end;
        text-align: right;
        width: 100%;
        text-align: center;

        .button {
            background: #ec6950;
            border: 0;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
            padding: 8px 25px;

            .buttonIcon {
                width: 20px;
                height: 30px;
                margin-left: 5px;
            }
        }
    }
}
