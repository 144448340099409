@import './../styles/variables';

.wrapper {
    padding: 15px;
}

.pageTitleSection {
    border-bottom: 1px solid transparent;
    background: $color-white;
    /*box-shadow: 0 4px 6px -6px $color-black;*/
    padding: 40px 0 20px 0;
    text-align: center;
    position: relative;

    h1 {
        font-size: 1.8rem;
    }

    .backIcon {
        cursor: pointer;
        left: 20px;
        top: 24px;
        color: $color-primary;
        font-size: 30px;
        font-weight: 600;
        bottom: 0;
        right: 0;
        margin-top: auto;
        margin-bottom: auto;
    }
}

.headerIcon {
    fill: $color-danger;
    transform: scale(1.5);
}

.newMessageBar {
    background-color: $color-primary;
    padding-top: 6px;
    padding-bottom: 6px;

    .newMessageTitle {
        color: $color-white;
        font-size: 16px;
    }
}

.inputBox {
    padding-bottom: 102px;
    width: 80%;

    .additionalMsgTxt {
        width: 100%;
        border-radius: 50px;
        margin: 0 auto;
        border: 1px solid $color-text-gray;
        padding: 10px 45px 10px 15px;
        height: auto;
        color: $messaging-text;
        font-size: 16px;
    }

    .inputIcon {
        position: absolute;
        background: $messaging-input-icon-color;
        width: 40px;
        height: 40px;
        right: 5px;
        bottom: 5px;
        border-radius: 50px;
        color: $color-white;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 69%;
            height: auto;
            transform: translate(-4%, 3%);
        }

        &.active {
            background: $color-secondary;
            cursor: pointer;
        }
    }

    .spanTextArea {
        overflow: hidden;
        resize: none;
        min-height: 50px !important;
        line-height: 20px;
        border: 1px solid $messaging-box-border;
        font-size: 16px;
        border-radius: 25px;
        color: $messaging-text;
        font-family: "Avenir", sans-serif;
        background: $color-white;
        white-space: pre-wrap;
    }

    .spanTextArea div::-webkit-scrollbar {
        display: none !important;
    }

    .inputkeyContainer::-webkit-scrollbar {
        display: none !important;
    }

    .spanTextAreaBox {
        color: $messaging-text;
        font-family: "Avenir", sans-serif;
        background: $color-white;
        white-space: pre-wrap;
        word-break: break-word;
        padding: 14px 20px;
        padding-right: 40px;

        &:focus-visible {
            outline: none;
        }
    }

    .spanTextAreaBox div::-webkit-scrollbar {
        display: none !important;
    }
}

.modalOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: $bg-backdrop;
    z-index: 9999;
}

.closeIcon {
    right: 20px;
    font-size: 25px;
    cursor: pointer;
    top: 6%;
}

.contactModal {

    .modalContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        width: 90%;
        background: $color-white;
        padding: 30px;
        font-size: 20px;

        .contactTitle {
            margin-bottom: 40px;
            text-align: center;
            font-size: 30px;
            color: $color-primary;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            border-top: 2px solid $color-text-gray;
            /*height: 400px;
            overflow-y: auto;*/

            li {
                padding: 20px 10px;
                border-bottom: 2px solid $color-text-gray;
                position: relative;
                font-size: 20px;
                color: $color-gray;
            }
        }

        .doneButton {
            color: $color-white;
            background-color: $color-secondary;
            font-size: 16px;

            &.buttonDisabled {
                background-color: #DFDFDF;
                cursor: default;
            }
        }
        .messageNames {
            float: left;
            width: auto;
            padding-left: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
        }
    }
    .iconView{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 43px;
        height: 43px;
        display: flex;
        border-radius: 50%;
        background-color: $color-danger;
    }
}

.msgContainer {
    display: flex;
    flex-direction: column;

    .msgDate {
        color: $messaging-text;
        font-size: 16px;
        font-family: "Avenir", sans-serif;
        margin: 8px 0 16px 0;
        text-align: center;
    }

    .questionContainer {
        .msgByName {
            margin-bottom: 5px;
        }

        .questionTag {
            background: $messaging-box-bg;
            padding: 10px;
            color: $messaging-text;
            font-size: 16px;
            width: 70%;
            position: relative;
            border-radius: 0 5px 5px 5px;
            white-space: pre-line;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: -16px;
                border-top: 0 solid transparent;
                border-right: 20px solid $messaging-box-bg;
                border-bottom: 20px solid transparent;
                background: transparent;
                border-left: transparent;
            }
        }

        .questionTime {
            font-size: 12px;
            padding: 13px 12px;
            text-align: left;
        }

        .attachment {
            float: left;
            clear: both;
            margin: 10px 0;
            width: auto;
            padding: 5px 10px;
            border-radius: 10px;
            background: $messaging-attachement-bg;
        }
    }

    .answerContainer {
        .answerTag {
            background: $color-secondary;
            padding: 10px;
            color: $color-white;
            font-size: 16px;
            width: 70%;
            position: relative;
            border-radius: 5px 0 5px 5px;
            white-space: pre-line;
            order: 2;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: -16px;
                border-top: 0 solid transparent;
                border-right: transparent;
                border-bottom: 20px solid transparent;
                background: transparent;
                border-left: 20px solid $color-secondary;
            }
        }

        .answerTime {
            font-size: 12px;
            padding: 13px 12px;
            text-align: right;
            order: 1;
        }

        .attachment {
            float: right;
            clear: both;
            margin: 5px 0;
            width: auto;
            padding: 5px 10px;
            border-radius: 10px;
            background: $messaging-attachement-bg;
        }
    }

    .messages {
        padding: 0 20px 10px;

        &.msgList {
            border-bottom: 3px solid #E9E9E9;
            padding: 10px 10px;
            cursor: pointer;
            display: flex;
        }

        .newMsg {
            width: 5%;
        }

        .msgContent {
            width: 65%;
            padding: 0 10px;

            span {
                display: block;
                width: 100%;

                &.msgfrom {
                    font-size: 18px;
                }

                &.msgtxt {
                    margin-top: 4px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1; /* number of lines to show */
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                    white-space: pre-line;
                }

                &.subject {
                    font-size: 18px;
                }

                &.subjectnew {
                    font-weight: 600;
                    font-size: 18px;
                }
            }
        }

        .msgDates {
            width: 30%;
            text-align: right;
            font-size: 17px;

            .msgContentIcon {
                font-size: 22px;
                color: $color-disabled;
            }
        }

        .msgNew {
            font-size: 50px;
            color: $color-danger;
            margin-left: -15px;
            margin-top: -13px;
        }
    }
}

.circleClose {
    color: $color-danger;
    font-size: 22px;
    cursor: pointer;
}

.sendMsg {
    cursor: pointer;
}

.createMsgContainer {
    position: relative;

    .msgUserName {
        color: $color-white;
        font-size: 18px;
    }
}

.messageContainer {
    padding: 10px 0 150px;
}

.messageWriteContainer {
    border: 1px solid #B2B2B2;
    position: absolute;
    border-radius: 20px;
    width: 90%;
    margin: 0 20px;
    padding: 10px 5px;
    display: inline-block;
    bottom: 30px;
}

.spanSubjectTextArea {
    display: block;
    width: 100%;
    overflow: hidden;
    resize: none;
    min-height: 55px;
    line-height: 35px;
    border-bottom: 1px solid $messaging-box-border;
    font-size: 20px;
    padding: 10px;
    text-align: left;
    color: $messaging-text;
    font-family: "Avenir", sans-serif;
    background: $color-white;

    &:focus-visible {
        outline: none;
    }

    &:empty::before {
        content: 'Subject:';
        display: inline-block;
        opacity: 0.5;
    }

    &:empty:focus::before {
        content: 'Subject:';
        opacity: 0.5;
    }
}

.spanMsgTextArea {
    display: block;
    width: 100%;
    overflow: hidden;
    resize: none;
    min-height: 55px;
    line-height: 35px;
    font-family: inherit;
    font-size: 20px;
    padding: 10px;
    text-align: left;
    font-weight: normal;
    color: $color-disabled;
    background: $color-white;
    word-wrap: break-word;

    &:focus-visible {
        outline: none;
    }

    &:empty::before {
        content: 'Message';
        display: inline-block;
    }

    &:empty:focus::before {
        content: 'Message';
    }
}

.inputIcon {
    position: absolute;
    background: $messaging-input-icon-color;
    width: 40px;
    height: 40px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    color: $color-white;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 69%;
        height: auto;
        transform: translate(-4%, 3%);
    }

    &.active {
        background: $color-danger;
    }
}

.contactSearchBox {
    margin-bottom: 20px;
    display: flex;
    .contactBox {
        padding: 5px 10px;
        border-radius: 15px;
        width: 88%;
        border: 1px solid $color-text-gray;
        height: 50px;
        opacity: 0.7;
    }
}

.messageSubject {
    font-weight: 600;
    color: $color-primary;
    font-size: 18px;
    font-family: "Avenir", sans-serif;
}

.messagerRemove {
    font-size: 30px;
    color: #CA212B;
}

.messagerAdd {
    font-size: 30px;
    color: #004B24;
}

.stickyHeader {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
    box-shadow: $header-shadow;
}

.iconView {
    width: 43px;
    height: 43px;
    background-color: $color-danger;
    bottom: 96px;
    right: 12px;
}

.bottomIcon {
    width: 50%;
    height: 50%;
    color: $color-white;
}

.attachmentContainer {
    max-width: 100%;
}

.attachmentWrap {
    min-height: 26px;
}

.attachmentButton {
    display: flex;
}
