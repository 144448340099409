@import '../../styles/variables';
@import "../../styles/common-imports";


.stickyHeader {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}

.travelFormContainer {
    background-color: #EBECEE;

    .formContainer {
        padding: 40px 30px;
    }

    .mainHeading {
        font-size: 20px;
        color: #565656;
        font-weight: bold;
    }

    .subHeading {
        font-size: 16px;
        color: #565656;
        font-weight: bold;
    }

    .travelSubmit {
        background: #ec6950;
        color: #fff;
        cursor: pointer !important;
        border: 0;
        border-radius: 50px;
        font-size: 16px;

        span {
            float: left;
            line-height: 25px;
            margin: 5px 0 0 15px;
            padding-top: 2px
        }

        .buttonIcon {
            color: #fff;
            height: 40px;
            padding: 7px 0;
            position: relative;
            width: 40px;
        }
    }


    .formElement {
        margin: 10px 0;
        display: inline-block;
        width: 100%;

        .formError {
            color: red;
            font-size: 12px;
        }

        label {
            font-size: 14px;
            color: #565656;
        }

        .colorRed {
            color: red;
        }

        .controlElement {
            border: 1px solid #707070;
            width: 100%;
            padding: 10px;
            border-radius: 50px;
            font-size: 16px;
            background: #fff;
        }

        textarea.controlElement {
            border-radius: 20px;
            resize: none;
        }

        .divhalf {
            width: 50%;
            float: left;
            padding: 0 5px;
        }

        .divBighalf {
            width: 60%;
            float: left;
            padding: 0 5px;
        }

        .divSmallhalf {
            width: 40%;
            float: left;
            padding: 0 5px;
        }

        .selectElement {
            border: 1px solid #707070;
            width: 100%;
            padding: 10px;
            border-radius: 50px;
            font-size: 16px;
            background: #fff;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-image: url("../../assets/images/down-caret.jpg");
            background-repeat: no-repeat;
            background-size: 11px;
            background-position: 95% center;
        }
    }

    .DonorCheckbox {
        span {
            margin-right: 1em;
        }

        label {
            color: #565656;
            font-size: 1em;
            margin: 0 1em;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        input[type=checkbox] {
            appearance: none;
            padding: 0;
            margin: 0 .75em;
            border: 1px #da7058 solid;
            width: 1.125em;
            height: 1.125em;
            border-radius: .15em;
            position: relative;
            top: .05em;
            background-color: #fff;
            color: #565656;
            margin-top: 1px;
            float: left;
        }

        input[type=checkbox]:checked {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpolyline fill='none' stroke='%23565656' stroke-width='3' stroke-linecap='round' stroke-line-join='round' stroke-miterlimit='10' points='1.87,9.64 6.53,16.25 16.13,1.75 '/%3E%3C/svg%3E%0A");
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 80% 80%;
        }

        input[type=radio] {
            appearance: none;
            padding: 0;
            margin: 0 .75em;
            border: 1px #da7058 solid;
            width: 1.125em;
            height: 1.125em;
            border-radius: 100%;
            position: relative;
            top: .05em;
            background-color: #fff;
            color: #565656;
            margin-top: 1px;
            float: left;
        }

        input[type=radio]:checked {
            content: '';
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18"><circle cx="9" cy="9" r="8.5"/></svg>');
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: 70% 70%;
        }
    }
}



.travelMyDetailsContainer {
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 8%;
        flex-direction: column;

        .topDiv {
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        .bottomDiv {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-top: auto;

            .detailsButton {
                background: #ec6950;
                color: #fff;
                cursor: pointer !important;
                border: 0;
                border-radius: 50px;
                font-size: 16px;
                align-self: end;
                margin-top: auto;
                min-width: 100px;
                padding: 10px 0px;
            }

            .buttonIcon {
                font-size: 22px;
                font-weight: bold;
                position: absolute;
                right: 5px;
            }
        }
    }
}

.travelDetailsContainer {
    .wrapper {
        padding: 8%;
    }

    .subHeading {
        font-size: 22px;
        color: #565656;
        margin-bottom: 10px;
        width: 100%;
        display: inline-block;
        font-weight: 600;
    }

    .detailsinfo {
        margin: 0 10px;

        h5 {
            font-weight: bold;
            font-size: 18px;
            color: #565656;
        }

        ul {
            li {
                .monitoringContacts {
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }

    ul {
        list-style: none;
        padding: 0px;
        display: inline-block;
        margin: 10px 0;
        width: 100%;

        li {
            margin-bottom: 15px;
            padding: 0;
            padding-left: 10px;
            width: 100%;
            white-space: pre-wrap;
        }
    }
}

.sectionHeading {
    color: #565656;
    margin-top: 10px;
    display: inline-block;
    width: 100%;
    font-size: 18px;
}

.contentText {
    font-size: 18px;
    color: #565656;
    line-height: 20px;

    span {
        width: 100%;
        display: inline-block;
    }

    .infoHead {
        font-size: 12px;
        color: #565656;
        font-weight: 500;
        margin-left: -10px;
    }

    .infoValue {
        font-size: 16px;
        margin-left: 10px;
        color: #565656;
    }

    .appointmentTime {
        font-size: 16px;
    }
}

.detailsAccordion {
    :global {
        .accordion-button {
            padding-left: 0;

            &:hover {
                background: none !important;
            }

            &:focus {
                box-shadow: none;
            }

            span {
                font-size: 23px;
                color: $color-primary;
                font-weight: 600;
            }
        }
    }
}


@include media-breakpoint-up(sm) {
    .formElement {
        .divBighalf {
            width: 60% !important;
            float: left;
            padding: 0 5px;
        }

        .divSmallhalf {
            width: 40% !important;
            float: left;
            padding: 0 5px;
        }
    }
}
