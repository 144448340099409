@import './../styles/variables';


.pageTitleSection {
    border-bottom: 1px solid transparent;
    background: $color-primary;
    box-shadow: $header-shadow;
    padding: 50px 0 20px 0;

    h1 {
        font-size: 1.5rem;
    }

    .closeIcon {
        right: 20px;
        top: 20%;
        color: $color-white;
        font-size: 20px;
    }
}


.stickyHeader {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}

.testingSection {
    padding: 50px;

    .testingKitDescription {
        color: $color-primary;
        font-size: 18px;
        margin-bottom: 30px;
    }

    .testingTrackPackage {
        margin-bottom: 15%;

        span {
            font-size: 18px;
            color: $color-primary;
            width: 100%;
            display: inline-block;
        }

        a {
            color: $color-primary;
            font-size: 16px;
            font-weight: bold;
        }
    }

    .testingCompleteInfo {
        color: $color-primary;
        font-size: 18px;
    }

    .completeButton {
        width: 100%;
        display: inline-block;
        text-align: center;
        margin-top: 10%;

        .button {
            padding: 10px 15px;
            border-radius: 50px;
            background-color: $color-danger !important;
            color: $color-white;
            font-size: 17px;
            border: 0;
            line-height: 20px;
        }
    }
}

.instructions {
    font-size: 16px;

    .spanKitInstructions {
        white-space: pre-wrap;
    }
}

.testingKitModal {
    .modalOverlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: $bg-backdrop;
        z-index: 9999;
    }

    .modalContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        width: 90%;
        background: $color-white;
        font-size: 20px;

        .modalTitle {
            color: $color-primary;
            padding: 24px 30px;
            font-size: 24px;
            font-weight: 700;
            text-align: center;
        }

        .spanKitInstructions {
            padding: 0 20px;
        }

        .footer {
            padding: 30px 0;
        }
    }
}
