@import '../styles/variables';

.wrapper {
    padding: 15px;
}

.closeIcon {
    font-size: 20px;
}

.blogSearch {
    padding: 20px 0;
    width: 80%;
    max-width: 300px;
    margin: 0 auto;
    position: relative;

    .categoryList {
        background: $color-white;
        cursor: pointer;
        border-radius: 50px;
        color: $color-disabled;
    }

    &::after {
        content: "\276F";
        position: absolute;
        top: 25px;
        right: 10px;
        display: inline-block;
        transform: rotate(90deg);
        padding: 1px;
    }
}

.blogContainer {
    ul {
        list-style: none;
        padding: 0;

        li {
            margin: 10px 0 10px 0;
            padding: 10px 20px;
            border-radius: 10px;
        }
    }

    .loadmore {
        background-color: $color-secondary;
        color: $color-white;
        padding: 10px 30px;
        border-radius: 10px;
        margin: 0 auto;
        display: block;
        font-weight: normal;
        font-size: 18px;
        margin-bottom: 30px;

        &:hover {
            color: $color-text-gray;
        }
    }
}

.blogListTitle {
    font-size: 28px;
    font-family: "Avenir", sans-serif;
    text-align: center;
    color: $color-primary;
}

.posts {
    .blogImage {
        margin-bottom: 20px;
        background-size: cover;
        background-position: center;
        width: 100%;
        max-width: 480px;
        height: 200px;
    }

    img {
        object-fit: cover;
        height: 100%;
        max-width: 100%;
    }

    .postDesc {
        margin: 10px 0;
        color: $color-primary;
        font-family: "Avenir";

        h1 {
            font-size: calc(1.8rem + 0.6vw) !important;
        }
        h1,h2,h3 {
            margin-top: 30px;
            margin-bottom: 20px;
        }

        a{
            text-decoration: none;
        }
    }
    .postTitle {
        margin: 10px 0 20px 0;
        color: $color-primary;
    }
    .postMisc {
        font-size: 14px;
        margin-bottom: 20px;
        color: $color-primary;
    }
    .readMore {
        display: inline-block;
        width: auto;
        text-align: center;
        color: $color-danger;
        text-decoration: none;
        padding: 3px 30px;
        background-color: $color-primary;
        color: $color-white;
        border-radius: 20px;
        font-size: 14px;
    }
}

.blogPage {
    padding: 20px;
}

.pageBlogHeader {
    padding: 32px 20px;
}

.blogBack {
    line-height: 20px;

    .blogbackArrow {
        font-size: 24px;
    }
}

.excerptMsg {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
