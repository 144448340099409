@import './../styles/variables';

.clsPasswordRequirement {
    h4 {
        font-weight: 600;
        font-size: 15px;
        color: $color-white;
        margin-top: 30px;
    }

    ul {
        padding: 0 0 0 10px;
        margin: 5px 0 20px 0;
        list-style: none;

        li {
            position: relative;
            font-size: 14px;
            line-height: 20px;
            color: $color-text-gray;
            display: flex;
            align-items: center;
            margin-bottom: 2px;

            svg {
                margin-right: 6px;
            }

            .colorDisable {
                color: $color-disabled;
            }

            .colorValidSuccess {
                color: $color-success;
            }

            .colorValidError {
                color: $color-danger;
            }
        }
    }
}

.containerBg {
    background-color: $color-primary;
    border-radius: 15px;
    margin-top: 20px;
}

.title {
    font-size: 30px;
    font-weight: 300;
    line-height: 42px;
    color: $color-white;
}

.subTitle {
    font-size: 18px;
    text-align: left;
    line-height: 20px;
    color: $color-white;
}

.context {
    text-align: left;
    font-size: 18px;
    color: $color-white;
    margin: 10px 0;
    line-height: 22px;
}
