@import './../styles/variables';


.stickyHeader {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}

.completeLabContainer {
    /*padding: 40px;*/
    height: calc(100% - 76px);

    .completeLabContent {
        flex: 1;
        padding: 40px 40px 0 40px;

        .attachmentLists {
            margin: 0 0 30px;

            h3 {
                font-size: 18px;
            }

            ul {
                padding-left: 0.5em;
                margin: 10px 0;

                li {
                    padding-top: 4px;
                    padding-bottom: 4px;
                    list-style: none;
                }
            }


            .labAttachment {
                span {
                    border-bottom: 1px solid $color-primary;
                    cursor: pointer;
                    font-size: .875em;
                }
            }
        }

        .appointmentText {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 30px;
        }
    }
}

.testingCompleteInfo {
    font-size: 18px;
    color: #353D56;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-bottom: 30px;
}

.completeButton {
    /*position: absolute;
    right: 20px;
    bottom: 15%;*/
    padding: 0px 40px 0 40px;
    margin: 5% 0;
    float: right;

    .appointmentButton {
        background-color: $color-danger !important;
        color: #fff;
        font-size: 16px;
        border-radius: 50px;
        border: 0px;
        padding: 5px 5px 5px 15px;
        float: right;
        cursor: pointer;
        position: relative;

        svg {
            width: 35px;
            height: 35px;
        }
    }
}

.scheduleAppointment {
    text-align: center;

    .appointmentButton {
        background-color: $color-primary;
        padding: 8px 15px;
        color: #fff;
        font-size: 16px;
        border-radius: 50px;
        width: 80%;
        margin: 0 auto;
        cursor: pointer;
        text-decoration: none;
    }
}

.linkToappointment {
    color: $color-primary;
    font-size: 16px;
    margin: 20px 0;

    a {
        font-weight: bold;
        width: 100%;
        display: inline-block;
    }
}

.step1 {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    height: calc(100% - 76px);
}

.step2 {
    padding: 40px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    height: 100%;

    .step2Input {
        /*margin: 10px auto;*/
        text-align: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        flex: 1;

        .inputControl {
            border-radius: 50px;
            border: 1px solid $color-primary;
            height: 50px;
            width: 80%;
            padding: 10px;
        }
    }
}

.datepickerWrap {
    width: 100%;
    max-width: $datepicker-max-width;
}

.customDatePickerStyle {
    padding: 11px 50px 11px 22px;
    width: var(--input-width);
    border-radius: 50px;
    border: 1px solid $color-primary;
}

.datepickerTxt {
    --input-width: 100%;
    width: 100%;
    margin: 10px 0;
    text-align: center;
    position: relative;

    input[type="text"] {
        padding: 11px 50px 11px 22px;
        width: var(--input-width);
        border-radius: 50px;
        border: 1px solid $color-primary;
    }

    .calendarIcon {
        font-size: 30px;
        position: absolute;
        right: calc((100% - var(--input-width))/2 + 20px);
        top: 8px;
        cursor: pointer;
    }
}

.submitButton {
    float: right;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    .button {
        background: #D6D6D6;
        border-radius: 50px;
        color: $color-white;
        width: auto;
        padding: 5px 10px 5px 20px;
        font-size: 16px;

        svg {
            width: 30px;
            height: 30px;
        }
    }

    .active {
        background: $color-danger !important;
        cursor: pointer;
    }
}

.labLocations {
    display: inline-block;
    width: 100%;
    text-align: center;

    button {
        background: $color-primary;
        color: $color-white;
        border-radius: 50px;
        padding: 10px 30px;
        margin-bottom: 8%;
    }
}

.labOverlay {
    background: $bg-backdrop;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 999;

    .labLocationContainer {
        background: $color-white;
        position: absolute;
        left: 0;
        right: 0;
        width: 90%;
        margin: 0 auto;
        transform: translateY(-50%) scale(1) translateZ(0px);
        padding-top: 10%;

        .labCloseIcon {
            top: 20px;
            right: 20px;
            width: 25px;
            height: 25px;
            cursor: pointer;
        }

        h3 {
            margin: 20px 0;
            color: $color-primary;
            font-size: 18px;
            font-weight: 600;
            padding: 0 30px;
        }

        ul {
            list-style: none;
            padding: 10px 30px;
            overflow-y: auto;

            li {
                margin: 0 0 30px 0;

                .locationHeading {
                    display: inline-block;
                    color: $color-primary;
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 2px;
                }

                span {
                    width: 100%;
                    display: inline-block;
                }
            }
        }
    }
}


@media (max-width: 425px) {

    .step2 {
        padding-left: 30px;
        padding-right: 30px;
    }

}


@media (max-width: 360px) {

    .datepickerTxt {
        --input-width: 95%;
    }

}
