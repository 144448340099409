@import './../styles/variables';

.wrapper {
    padding: 15px;
}

.pageTitleSection {
    border-bottom: 1px solid transparent;
    background: $color-primary;
    box-shadow: $header-shadow;
    position: relative;
    z-index: 1;

    h1 {
        font-size: 1.5rem;
    }

    &::after{
        content: " ";
        display: block;
        width: 100%;
        height: 20px;
        box-shadow: 0px 4px 10px -4px #000;
        position: absolute;
        z-index: 999;
        bottom: 0;
    }
}

.periodTrackerIcon {
    margin-top: -10px;
}

.periodTrackerText {
    font-size: 20px;
}

.taskWrapper {
    overflow-y: auto;
    position: relative;

    :global {
        .accordion-button {
            padding-left: 0;
            &:hover {
                background: none !important;
            }
            &:focus {
                box-shadow: none;
            }
        }
    }
}

.taskName {
    font-size: 20px;
    font-weight: 600;
    color: $color-primary;
}

.taskLists {
    list-style: none;
    padding: 0;
    margin: 0;

    span {
        display: block;
        font-size: 15px;
        font-weight: normal;
        color: $color-primary;
    }

    .taskSmallName {
        width: 70%;
    }

    .taskDueName {
        width: 20%;
        text-align: center;
    }

    .taskActionName {
        width: 10%;
        text-align: right;
    }

    .mainTasks {
        margin: 5px 0;
        position: relative;

        .task {
            display: flex;
            align-items: center;
            text-decoration: none;
            padding: 6px 12px;
        }

        .taskSmallName {
            width: 70%;
            font-size: 18px;
            line-height: 23px;
            padding-right: 8px;
        }

        .taskDueName {
            position: relative;
            top: 1px;
            font-size: 13px;
            line-height: 16px;
        }

        .taskActionName {
            display: flex;
            justify-content: flex-end;
            padding-right: 0;

            svg {
                display: block;
            }
        }
    }

    .taskHead {
        padding: 0 12px;
        display: flex;

        span {
            font-size: 12px;
        }
    }

    .taskNew,
    .taskReSubmitted {
        font-weight: bold;

        span {
            font-weight: bold;
        }

        &.taskOverdue,
        &.taskImportant {
            font-weight: normal;

            span {
                font-weight: normal;
            }
        }
    }

    .taskOverdue,
    .taskReSubmitted,
    .taskImportant {
        background: rgba($color-danger,.25);
        border-radius: 20px;

        .taskDueName {
            color: $color-danger;
            font-weight: bold !important;
        }
    }
}

.completeTaskWrap {
    box-shadow: 0 4px 4px 0 rgba($color-black, .16);
    background-color: $color-danger;
    border-radius: 18px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    button {
        background-color: transparent;
        border: none;
    }
}

.completeTask {
    position: relative;
    width: 100%;
    font-size: 18px;
    line-height: 35px;
    color: $color-white;
    text-align: center;
    z-index: 1;
}

.completeCancel {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    line-height: 25px;
    color: $color-white;
    padding: 5px 10px;
    z-index: 2;
}

.dotClasses {
    width: 10px;
    height: 10px;
    background-color: $color-danger;
    margin-right: 5px;
}

.impClasses {
    font: bold 18px "Arial", sans-serif !important;
    width: 10px;
    margin-right: 5px;
}
