
@import '../styles/variables';

.wrapper {
    padding: 15px;
}

button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
}

.docUL {
    list-style: none;
    padding: 0 10px;
    .docLists {
        margin: 10px 0;
        width: 100%;
        display: inline-block;
    }

    .docName {
        font-weight: bold;
        font-size: 16px;
        color: $color-primary;
        width: 100%;
        display: inline-block;
        font-family: "Avenir";
    }
    span.docChkBox {
        float: left;
        width: 10%;
        text-align: center;
        input {
            background-color:#353D56 !important;
        }
        .form-check-input {
            margin-top: 5px;
            background-color: red !important;

            &:focus {
                box-shadow: 0;
            }
        }
    }

    div {
        float: left;
        width: 80%;
    }
}

.submitSchedular {
    button {
        background: none;
        border: 0px;
        color: $color-primary;
        font-size: 16px;
    }

    .buttonIcon {
        background: $color-danger;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 5px;
        color: $color-white;
        position: relative;
    }
}

.datepickerWrap {
    width: 80%;
    max-width: $datepicker-max-width;
}

.datepickerTxt {
    --input-width: 100%;
    width: 100%;
    padding: 10px 0;
    text-align: center;
    position: relative;

    input[type="text"] {
        padding: 11px 50px 11px 22px;
        width: var(--input-width);
        border-radius: 50px;
        border: 1px solid $color-primary;
    }

    .calendarIcon {
        font-size: 30px;
        position: absolute;
        right: calc((100% - var(--input-width))/2 + 20px);
        top: 17px;
        cursor: pointer;
    }
}
