@import "./variables";
@import "./common-imports";

body {
    font-family: $font-family-base, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}

html,
body {
    height: 100%;
    oversroll-behaviour-y: contain; /* disable default pull to refresh, keeps glow effects */
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100dvh;
    /* Fix for mobile devices */
   /* min-height: 100vh;
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: fill-available;
    max-height: 100vh;*/
}

.app {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    width: 100%;
    position: relative;
    overflow-y: auto;
    height: 100%;
}

.ptr__children {
    display: flex;
    flex-direction: column;
}

.ptr__pull-down--pull-more {
    text-align: center;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-pointer {
    cursor: pointer;
}

.fw-medium {
    font-weight: 500 !important;
}

.carousel-inner {
    overflow: inherit;
}

.scroll-container {
    > div:first-child {
        overflow-x: hidden !important;
        overflow-y: auto !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-bottom: 0px !important;
    }

    > div:first-child::-webkit-scrollbar {
        display: none;
    }
}

@include media-breakpoint-up(sm) {
    #root {
        height: 100dvh;
    }
    .app {
        max-width: 480px;
        /*max-height: 960px;*/
        margin: 0 auto;
        border-radius: 25px;
        overflow-y: auto;
        height: 100%;
        overflow-x:hidden;
    }
}
