@import './../styles/variables';

.loginValidateContainer {
    min-height: 50px;
}

.loginContainerBG {
    background-color: $color-primary;
    border-radius: 15px;
    margin-top: 20px;
}

.frmValidateMsg {
    font-size:12px !important;
    padding: 0 10px;
}

.forgetPwdLink a {
    color: $color-white;
    font-size:14px;

    &:hover{
        color: $color-secondary;
    }
}

.rememberLogin {
    color: $color-white;
    height: 50px;
    display: inline-block;
    padding: 30px 0 0;
    vertical-align: middle;
    width: 100%;
    font-size: 14px;

    input[type='checkbox'] {
        float: none !important;
        margin-right: 10px;
        background-color: $color-secondary !important
        
    }
    label {
        color: $color-white !important;
    }
}

input {
    background-color: $color-white !important;
}
