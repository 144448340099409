@import './../styles/variables';


.stickyHeader {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}

.clinicContainer {
    padding: 11%;

    .heading {
        font-size: 20px;
        color: $color-primary;
        font-weight: bold;
        font-family: "avenirLtdBook";
        margin-bottom: 10px;
    }

    .subheading {
        font-size: 18px;
        color: $color-primary;
        font-weight: bold;
        font-family: "avenirLtdBook";
        margin-bottom: 6px;
    }

    .contactHeading {
        color: $color-primary;
        font-weight: bold;
        font-family: "avenirLtdBook";
    }

    .head {
        font-weight: bold;
    }

    .coOrdinator {
        margin-bottom: 35px;
        margin-top: 20px;
        padding-left: 10px;

        span {
            display: inline-block;
            width: 100%;
            color: $color-primary;
        }

        a {
            color: $color-primary;
            text-decoration: underline;
            display: inline-block;
            width: 100%;
        }
    }

    .yourClinic {
        span {
            display: inline-block;
            width: 100%;
            color: $color-primary;
        }
    }

    .yourContact {
        margin: 5px 0 10px 0;

        span {
            display: inline-block;
            width: 100%;
            color: $color-primary;
        }

        span.clinicCordName {
            font-weight: bold;
        }

        a {
            text-decoration: underline;
            color: $color-primary;
        }
    }
}
