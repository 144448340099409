@import './../../../styles/variables';

.taskDescription {
    position: relative;

    .adhocTaskDescription {
        font-size: 18px;
        text-align: left;
    }

    .rejectedDescription {
        text-align: left;

        strong {
            color: red;
        }
    }
}
