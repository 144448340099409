@import "../../styles/variables";

.rowContent {
  justify-content: center;
  flex-direction: row;
}

.wrapper {
  padding: 15px;
}
.periodTrackerIcon {
  width: 70px;
  height: 50px;
}
.periodTrackerText {
  text-align: center;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 37px;
  font-family: Avenir;
  letter-spacing: 0px;
  color: #353D56;
  opacity: 1;
  // text-align: center;
  // letter-spacing: 0px;
  // color: #353D56;
  // opacity: 1;
  // font-size: 25px;
}
.pageTitleSection {
  border-bottom: 1px solid transparent;
  background: $color-white;
  padding-top: 15px;
  text-align: center;
  // position: fixed;
  h1 {
    font-size: 1.8rem;
    color: $color-primary;
  }
  .closeIcon {
    left: 20%;
    position: relative;
    top: 50px;
    width: 18px;
    height: 18px;
    opacity: 1;
    top:20%;
  }
  .backIcon {
    left: 20px;
    top: 60px;
    color: $color-primary;
    width: 25px;
    height: 25px;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
}
.stickyHeader {
  // align-items: center;
  // justify-content: center;
  margin-top:5px;
  background: green;
  // padding: 50px 0 5px 0;
  position: fixed;
  width: 58.3%;
  border-radius: 0px 0px 23px,23px;
  // z-index: 999px;
  // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
}

.lightPeriod {
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  font-family: Avenir;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #484848;
  opacity: 1;
}

.heavyPeriod {
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  font-family: Avenir;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0px;
  color: #484848;
  opacity: 1;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.primaryButton {
  width: 90px;
  height: 35px;
  border-radius: 23px;
  background: #353D56 0% 0% no-repeat padding-box;
  opacity: 1;
}
.primaryColor{
  background: #353D56 0% 0% no-repeat padding-box;
}
.primaryDisableColor{
  background: #5a5d67 0% 0% no-repeat padding-box;
}

.primartText {
  text-align: center;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  font-family: Avenir;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
.secondaryButton {
  width: 90px;
  height: 35px;
  border-radius: 23px;
  background: #ec6950 0% 0% no-repeat padding-box;
  opacity: 1;
}
.secText {
  text-align: center;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  font-family: Avenir;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #353d56;
  opacity: 1;
}
.periodEnd {
  width: 90px;
  height: 35px;
  background: #f9d1ca 0% 0% no-repeat padding-box;
  border-radius: 23px;
  opacity: 1;
}
.buttonTitle {
  text-align: center;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  font-family: Avenir;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0px;
  color: #353d56;
  opacity: 1;
}
.nextProject {
  text-align: center;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  font-family: Avenir;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #353d56;
  opacity: 1;
}

.removeButton {
  width: 90px;
  height: 35px;
  background: #8c95b6 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 23px;
}

.removeTitle {
  text-align: center;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  font-family: Avenir;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.removeRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1px;
}
.childDiv{
  margin: 5px;
}
.periodStartDiv{
  margin-top: 30px;
}
.startTextDiv{
  margin-top: 10px;
}
.periodStart {
  top: 1032px;
  left: 1371px;
  width: 198px;
  height: 45px;
  background: #353D56 0% 0% no-repeat padding-box;
  border-radius: 23px;
  opacity: 1;
}

.screenLayout{
    position: relative;
    overflow: hidden;
    width: 100%;
    flex: 1 1 auto;
}

.screenoutLayout{
    position: relative;
    overflow: auto;
    height:100%;
}

.taskLegend {
  ul {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 4px 0 5px 0;
    padding: 12px 0;
    -webkit-box-shadow: -1px -5px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -1px -5px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -1px -5px 5px 0px rgba(0, 0, 0, 0.1);

    li {
      margin: 0 5px;
      flex-direction: row;
      &:before {
        content: "\A";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px;
      }
    }

    li.task {
      width: 37px;
      height: 7px;
      background: #f9d1ca 0% 0% no-repeat padding-box;
      border-radius: 7px;
      opacity: 1;
    }

    li.medication {
      margin-left: 15px;
      width: 38px;
      height: 7px;
      background: #ec6950 0% 0% no-repeat padding-box;
      border-radius: 7px;
      opacity: 1;
    }
  }
  padding-bottom: 76px;
}

.headerIcon {
  fill: $color-primary;
  transform: scale(1.5);
  margin-right: 10px;
  width: 27px;
}

.calendarContainer {
  // height: 310px;
  // overflow-y: scroll;
  // top: 100px;
  // position: absolute;
  // display: block;
  //overflow-y: auto !important;
}
.calendarFixOndate {
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.2);
  height: 112px;
  transform: translateY(64.6843px);
}

.taskInfo {
  padding: 5px 20px;
  .selectedMonth {
    line-height: 22px;
    font-size: 22px;
    color: $color-danger;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
  }

  .noOfTasks {
    font-size: 18px;
    line-height: 18px;
    color: $color-text;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
  }
}

.taskList {
  padding: 0px 20px;

  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;

    li {
      margin: 10px 0;
      border-radius: 5px;
      border: 1px solid $color-taskborder;
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-content: flex-start;

      .appointMent {
        width: 80%;
        cursor: pointer;

        span {
          display: inline-block;
          width: 100%;
          line-height: 24px;
          color: $color-tasktext;
          vertical-align: middle;
        }
      }

      .appointMentIcon {
        span {
          margin: 0 10px;
          cursor: pointer;
        }
      }

      .doneButton {
        background-color: $color-secondary;
        color: $color-white;
        padding: 5px 30px;
        border-radius: 50px;
        line-height: 29px;
        font-size: 18px;
      }
    }
  }
}

.modalOverlay {
  background: $bg-backdrop;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}


/* GRID */

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  text-align: center;
  color: $color-primary;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}


.notifyModalContainer {
    background-color: rgba($color-black, 0.5);
    z-index: 9990;
}

.notifyModal {
    min-height: 170px;
    background-color: #fff;
    left: 20px;
    right: 20px;
    border-radius: 10px;
    border: 0;

    .modalHeader {
        background-color: #353d56;
        padding: 15px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #fff;
        font-size: 22px;
        text-align: center;
        font-weight: bold;
    }

    .modalDetails {
        padding: 10px;
        font-size: 20px;
        text-align: center;
    }

    .modalFooter {
        text-align: center;
        padding: 5px 5px 10px 5px;

        .footerbutton {
            position: relative;
            margin: 10px;
            width: auto;
            padding: 8px 20px;
            background-color: $color-danger !important;
            color: #fff;
            font-size: 20px;
            border: 0px;
            border-radius: 10px;
        }
    }
}

