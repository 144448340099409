@import './../styles/variables';

.wrapper {
    padding: 15px;
}

.inputBox {
    width: 80%;

    .additionalMsgTxt {
        width: 100%;
        border-radius: 50px;
        margin: 0 auto;
        border: 1px solid $color-text-gray;
        padding: 10px 45px 10px 15px;
        height: auto;
        color: $messaging-text;
        font-size: 16px;
    }

    .inputIcon {
        position: absolute;
        background: $messaging-input-icon-color;
        width: 40px;
        height: 40px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        color: $color-white;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;

        svg {
            width: 69%;
            height: auto;
            transform: translate(-4%, 3%);
            cursor: default;
        }

        &.active {
            background: $color-danger;
            cursor: pointer;

            svg {
                cursor: pointer;
            }
        }
    }

    .spanTextArea {
        overflow: hidden;
        resize: none;
        min-height: 50px;
        line-height: 20px;
        border: 1px solid $messaging-box-border;
        font-size: 16px;
        padding: 0px; /*14px 50px 14px 10px;*/
        border-radius: 25px;
        color: $messaging-text;
        font-family: "Avenir", sans-serif;
        background: $color-white;
    }
}

.msgContainer {
    display: flex;
    flex-direction: column;

    > div:first-child {
        flex-grow: 1;
    }

    .msgDate {
        color: $messaging-text;
        font-size: 16px;
        font-family: "Avenir", sans-serif;
        margin: 8px 0 16px;
        text-align: center;
    }

    .messages {
        padding: 0 20px 10px;
    }

    .questionContainer {
        .msgByName {
            margin-bottom: 5px;

            &.highlight {
                color: $color-skyblue;
                font-size: .875rem;
            }
        }

        .questionTag {
            background: $messaging-box-bg;
            padding: 10px;
            color: $messaging-text;
            font-size: 16px;
            width: 70%;
            position: relative;
            border-radius: 0 5px 5px 5px;
            white-space: pre-line;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: -16px;
                border-top: 0 solid transparent;
                border-right: 20px solid $messaging-box-bg;
                border-bottom: 20px solid transparent;
                background: transparent;
                border-left: transparent;
            }
        }

        .questionTime {
            font-size: 12px;
            padding: 13px 12px;
            text-align: left;
        }

        .attachment {
            margin: 5px 0;
            padding: 5px 10px;
            border-radius: 10px;
            background: $messaging-attachement-bg;
        }
    }

    .answerContainer {
        .answerTag {
            background: $color-secondary;
            padding: 10px;
            color: $color-white;
            font-size: 16px;
            width: 70%;
            position: relative;
            border-radius: 5px 0 5px 5px;
            white-space: pre-line;
            order: 2;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: -16px;
                border-top: 0 solid transparent;
                border-right: transparent;
                border-bottom: 20px solid transparent;
                background: transparent;
                border-left: 20px solid $color-secondary;
            }
        }

        .answerTime {
            font-size: 12px;
            padding: 13px 12px;
            text-align: right;
            order: 1;
        }

        .attachment {
            margin: 5px 0;
            padding: 5px 10px;
            border-radius: 10px;
            background: $messaging-attachement-bg;
        }
    }
}

.spanTextAreaBox {
    white-space: pre-wrap;
    word-break: break-word;
    padding: 14px 20px;
    padding-right: 40px;

    &:focus-visible {
        outline: none;
    }
}

.spanTextAreaBox div::-webkit-scrollbar {
    display: none !important;
}

.attachmentContainer {
    max-width: 100%;
}

.attachmentWrap {
    min-height: 26px;
}

.attachmentButton {
    display: flex;
}

.circleClose {
    color: $color-danger;
    font-size: 22px;
    cursor: pointer;
}

.sendMsg {
    cursor: pointer;
}
