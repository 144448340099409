@import "../styles/variables";

.title {
    font-size: 20px;
    line-height: 20px;
    color: $color-primary;
}

.alertLink {
    font-weight: 600;
    color: $color-danger;
}
