.clsCalendarClsName {
    font-size: 14px;

    &.react-datepicker {
        display: inline-flex;
    }

    .react-datepicker__time-container  {
        width: 90px;
    }

    .react-datepicker__time-box {
        width: 90px !important;
    }

    .react-datepicker__time-box ul {
        overflow-x: hidden;
    }
}

.react-datepicker-popper {
    z-index: 88;
}
