@import './../../../styles/variables';

.timeZoneContainer {
    text-align: center;
    position: relative;

    span {
        margin: 0 5px;
    }

    .label {
        color: $color-primary;
        font-size: 14px;
    }

    .listHeading {
        font-size: 18px;
        color: $color-primary;
        font-weight: 600;
        cursor: pointer;
    }
}

.timeZoneOverlay {
    background: $bg-backdrop;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 999;
}

.timeZoneListContainer {
    background: $color-white;
    left: 0;
    right: 0;
    width: 80%;
    max-width: 242px;
    margin: 0 auto;
    transform: translateY(100%) scale(1) translateZ(0px);
    // padding-top: 2%;
    z-index: 9999;

    .zoneModalClose {
        top: 10px;
        right: 20px;
        width: 14px;
        height: 14px;
        cursor: pointer;
    }

    ul.zonelist {
        list-style: none;
        margin: 0 auto;
        padding: 10px 0;

        li {
            font-size: 18px;
            color: $color-primary;
            font-weight: 600;
            padding: 4px 0;
            cursor: pointer;
            transition: background-color .3s ease;

            &:hover {
                background: $color-gray-light2;
            }
        }
    }
}
