@import './../styles/variables';

.wrapper {
    padding: 15px;
}

.backButton {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;

    a {
        cursor: pointer;
        color: #353D56;
    }

    svg {
        font-size: 30px;
        margin-top: -5px;
    }
}

.interviewCalc {
    .dayHeader {
        display: flex;
        width: 100%;
        margin: 0 auto;
        background: #F1F1F1;
        padding: 0px;
        position: relative;

        .headerLeft {
            width: 10%;
            text-align: center;
            font-size: 35px;
            cursor: pointer;

            svg {
                top: 50%;
                position: absolute;
                left: 0;
                transform: translateY(-50%);
            }
        }

        .headerRight {
            width: 10%;
            text-align: center;
            font-size: 35px;
            cursor: pointer;

            svg {
                top: 50%;
                position: absolute;
                right: 0;
                transform: translateY(-50%);
            }
        }

        .headerCurrentDate {
            width: 80%;
            text-align: center;
        }

        .headerCurrentDate {
            font-size: 20px;
            color: #424143;
            font-family: "Avenir";
            padding: 0px 0;
            font-weight: 600;

            .boldDateHeader {
                font-weight: 700;
            }
        }

        .disable {
            color: #ddd;
            cursor: default;

            svg {
                cursor: default;
            }
        }
    }

    .InterviewListHead {
        display: flex;
        width: 80%;
        margin: 20px auto 5px;
        padding: 0 30px;

        .interviewHead {
            &:first-child {
                width: 40%;
            }

            &:last-child {
                width: 60%;
            }
        }
    }

    .InterviewList {
        display: flex;
        width: 90%;
        margin: 0px auto;
        padding: 10px 30px;
        cursor: pointer;

        &.activeTime {
            background-color: #F1F1F1;
        }

        div {
            font-size: 20px;
            text-align: center;
        }
    }

    .norecords {
        margin: 30px auto;
        text-align: center;
        background-color: $bg-backdrop;
        border-radius: 20px;
        color: #fff;
        padding: 20px;
        width: 70%;
        opacity: 0.5;
    }
}

.interviewFields {
    max-width: 90%;
    margin: 0 auto;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    border-top: 2px solid #707070;
    flex: 1;

    &.hidden {
        display: none;
    }

    .labelName {
        display: inline-block;
        width: auto;
        font-family: "Avenir";
        font-size: 18px;
        color: $color-text;
        text-align: right;
        padding: 0 15px 0 0;
        margin-bottom: 10px;
    }

    .fieldControl {
        display: inline-block;
        width: 85%;
        margin-bottom: 10px;

        input {
            border: 1px solid $color-taskborder;
            border-radius: 50px;
            padding: 10px;
            font-size: 20px;
            color: $color-text;
            width: 100%;
        }
    }

    .interviewInfo {
        font-size: 18px;
        margin: 10px 0;
    }
}


.flatControl {
    display: inline-block;
    width: 80%;
    margin: 10px 0;

    input {
        border: 1px solid #707070;
        padding: 5px;
        font-size: 20px;
        color: $color-text;
        width: 100%;
    }
}

.clsVideoPrefence {
    width: 100%;
    background-color: #fff;
    padding: 8px 5px;
    border: 1px solid #707070;
    font-size: 18px;
}
.submitArrow {
    background: $messaging-input-icon-color;
    padding: 5px 20px;
    font-size: 17px;
    height: 40px;
    padding: 0 0 0 1.5em;
    width: 130px;
    border-radius: 20px;
    color: $color-white;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    &.active {
        background: $color-danger;
        cursor: pointer;
    }
    .scheduleArrow {
        font-size:36px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.ackTitle {
    color: #414042;
    width: 50%;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 30px;
    font-size: 30px;
}

.ackFor {
    width: 100%;
    color: #414042;
    display: block;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 40px;
    border-bottom: 1px solid #707070;
}

.ackInfo {
    width: 85%;
    margin: 0 auto 20px;

    span:first-child {
        width:30%;
        display: inline-block;
        text-align: right;
        margin:0 5px;
        vertical-align: top;
        color: $color-taskborder;
        font-weight: 600;
        padding: 0 20px;
    }
    span:last-child {
        width: 60%;
        display: inline-block;
        color: $color-text;
        font-weight: 600;
    }
}
.mainContainer {
    display: flex;
    flex-direction: column;
    height: 100%;

    .pageTitleSection {
        justify-content: center;
        display: flex;
        flex: 0;
    }

    .interviewContainer {
        display: flex;
        flex: 1;
        flex-direction: column;

        .acuityContainer {
            display: flex;
            flex-direction: column;
            flex: 1;

            .interviewCalc {
                padding-left: 1rem;
                padding-right: 1rem;
                display: flex;
                flex-flow: column nowrap;
                justify-content: space-between;
                flex: 1;
            }

            .bottomSection {
                display: flex;
                flex-flow: column nowrap;
                justify-content: flex-start;
                flex: 1;

                .scheduleButton {
                    display: flex;
                    justify-content: flex-end;
                    flex: 0;
                }
            }
        }
    }
}

.timeZone {
    h4 {
        text-align: center;
        padding: 10px;
        background: #F1F1F1;
        font-size: 18px;
        font-weight: 700;
        color: #353D56;
    }
}

.zoneList {
    padding: 0px;
    margin: 0 auto;
    max-width: 70%;

    li {
        list-style: none;
        margin: 15px 0;
        font-size: 18px;
        cursor: pointer;
        font-weight: 500;
        color: #414042;
    }
}

.days {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    /*padding: .75em 0;*/
    flex-direction: row;
    display: flex;
    align-content: center;
    width: 450px;
    background: #fff;
    max-width: 100%;
    z-index: 99;
    /*padding: 0 1.5rem !important;*/
}

.col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    text-align: center;
    color: #353D56;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 17px;
}


.CalendarDays {
    /*padding: 0 1.5rem;*/
    /*margin: 1rem 0;*/

    .dayRow {
        display: flex;
        flex-direction: row;
    }
}

.calendarDate {
    padding: 0px;
    text-align: center;
    /*height: 40px;
    width: 40px;*/
    flex-grow: 1;
    cursor: pointer;
    margin: 5px;
    position: relative;

    span {
        padding: 5px 8px;
        /*display: block;*/
        /*margin: 10px 0;*/
        width: 33px;
        display: inline-block;
        height: 33px;
        position: relative;
        z-index: 2;
        font-size: 16px;
    }

    .circleIndicator {
        opacity: 0;
        position: absolute;
        width: 25px;
        height: 25px;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        top: 50%;
        margin: auto;
        border: 2px solid $color-danger;
        border-radius: 100%;
        z-index: 99;
    }

    &.disabled {
        opacity: 0; /*0.5;*/
        cursor: default;
    }

    &.currentDate {
        span.circleIndicator {
            opacity: 1;
        }
    }

    &.selected {
        border: 1px solid $color-danger;
        background-color: $color-danger;
        color: #fff;
    }
}

.slotAvailable {
    background-color: #FAD9D3;
    border: 0px;
    border-radius: 3px;
}

.backtoMonth {
    margin: 5px 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
}

.availTimeZoneSelect {
    background: #fff;
    width: 80%;
    border: 0px solid #ddd;
    padding: 10px 20px;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
}

.availableTimeSlots {
    height: 250px;
    margin-top: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    & > div {
        overflow-y: auto !important;
        overflow-x: hidden !important;
    }
}

.step2 {
    flex: 1;
}

.step4 {
    flex: 1;
}

.interviewAckPage {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .scheduledFor {
        flex: 1;
        padding: 60px 30px 20px;
    }

    .scheduledPlace {
        flex: 1;
        padding: 0px 20px;
    }

    .ackButton {
        flex: 0.4;
        display: flex;
        align-self: end;

        .ackArrowButton {
            align-self: flex-end;
            margin-bottom: 20px;
            margin-right: 20px;
            background: #ddd;
            padding: 5px 30px 5px 20px;
            font-size: 17px;
            border-radius: 50px;
            color: #fff;
            line-height: 28px;
            position: relative;
            float: right;
            margin-right: 30px;
            background: $color-danger;
            cursor: pointer;

            .scheduleArrow {
                font-size: 36px;
                position: absolute;
                right: -4px;
                top: 2px;
            }
        }
    }
}
