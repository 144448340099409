@import '../../styles/variables';

.wrapper {
  padding: 15px;
}
// .lineOftask{
//   padding: 15px;
// }
.noTask{
  text-align: center;
}
.sticky {
  position: relative;
  align-items: center;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
}
.taskText {
  opacity: 1;
  text-align: center;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  font-size: 28px;
  line-height: 43px;
  font-family: Avenir;
  letter-spacing: 0px;
  color: #353D56;
  opacity: 1;
  margin-left: 10px;
  vertical-align: middle;
}
.col {
  flex-grow: 1;
  flex-basis: 0px;
  text-align: center;
  color: $color-primary;
  vertical-align: text-bottom;
}
.pageTitleSection {
  border-bottom: 1px solid transparent;
  background: $color-white;
  padding: 20px 0 5px 0;
  text-align: center;
  h1 {
    font-size: 1.8rem;
    color: $color-primary;
  }
  .closeIcon {
    right: 20px;
    position: absolute;
    width: 18px;
    height: 18px;
    opacity: 1;
    top:20%;
  }
  .backIcon {
    left: 20px;
    top: 30px;
    color: $color-primary;
    width: 25px;
    height: 25px;
    right: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.headerIcon {
  fill: $color-primary;
  transform: scale(1.5);
  margin-right: 10px;
  width: 27px;
}
.calendarContainer {
}
.mainDiv {
    /*overflow-y: auto;
  height: 24vh;
  margin-bottom:calc(76px - 1rem);*/
    overflow-y: auto;
    height: 50vh;
    margin-bottom: 20px;
    flex: 0.3 auto;
}
/* Media query for desktop */
@media (min-width: 768px) {
  .mainDiv {
    overflow-y: auto;
  }
}

.screenLayout {
    position: relative;
    overflow: hidden;
    height: 50vh;
    width: 100%;
    flex: 1 1 auto;
}

.screenoutLayout{
    position: relative;
    overflow: auto;
    height:100%;
}

.taskLegend {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-bottom:0.25rem;
    -webkit-box-shadow: -1px -5px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -1px -5px 5px 0px rgba(0, 0, 0, 0.1);
    height: 32px;
    li {
      margin: 0 5px;
      &:before {
        content: "\A";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px;
      }
    }
    div {
      box-shadow: -1px -5px 5px 0 rgba(0,0,0,.1);
    }

    li.task {
        font-weight: normal;
        font-variant: normal;
        font-style: normal;
        font-size: 12px;
        line-height: 19px;
        font-family: Avenir;
        letter-spacing: 0px;
        color: #616161;
        opacity: 1;
      &:before {
        // background: $color-danger;
        background: #EC6950 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }

    li.medication {
      font-weight: normal;
      font-variant: normal;
      font-style: normal;
      font-size: 12px;
      line-height: 19px;
      // font-family: Avenir;
      letter-spacing: 0px;
      color: #616161;
      opacity: 1;
      &:before {
        // background: $color-secondary;
        background: #8C95B6 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 10px;
        height: 10px;
        border-radius: 50%;

      }
    }

    li.appointment {
        font-weight: normal;
        font-variant: normal;
        font-style: normal;
        font-size: 12px;
        line-height: 19px;
        font-family: Avenir;
        letter-spacing: 0px;
        color: #616161;
        opacity: 1;
      &:before {
        // background: $color-skyblue;
        background: #6AB4BD 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
  }
}

.taskInfo {
  padding-left: 18px;
  .selectedMonth {
    line-height: 22px;
    font-size: 22px;
    color: $color-danger;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
  }
  .noOfTasks {
    font-size: 18px;
    line-height: 18px;
    color: $color-text;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
  }
}

.taskList {
  padding: 0 20px;
  bottom: 0;
  // position: absolute;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 7px 0;
      border-radius: 5px;
      border: 1px solid $color-taskborder;
      padding: 5px 10px;
      display: flex;
      flex-direction: row;
      align-content: flex-start;

      .appointMent {
        max-width: calc(100% - 50px);
        padding-right: 10px;

        span {
          display: inline-block;
          width: 100%;
          line-height: 20px;
          color: $color-tasktext;
          vertical-align: middle;
        }
      }

      .appointMentIcon {
        max-width: 50px;

        span {
          padding: 2px;

            + span {
                margin-left: 10px;
            }
        }
      }

      .doneButton {
        background-color: $color-secondary;
        color: $color-white;
        padding: 5px 30px;
        border-radius: 50px;
        line-height: 29px;
        font-size: 18px;
      }
    }
  }
}

.modalOverlay {
  background: $bg-backdrop;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

.remainderOverlay {
  background: #fff;
  font-size: 20px;
  left: 50%;
  padding: 30px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 9999;

  ul {
    margin: 20px auto;
    padding: 0px;
    list-style: none;
    width: 80%;

    li {
      padding: 10px 20px;
      border-top: 1px solid $color-text-gray;
      border-bottom: 1px solid $color-text-gray;
      color: $color-text;
      cursor: pointer;

      &.selectedReminder {
        background-color: $color-secondary;
        opacity: 0.8;
      }
    }
  }
}



/**/

/* GRID */

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  text-align: center;
  color: $color-primary;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
}


/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}

.calendar .header {
  font-size: 18px;
  padding: 2px 0;
  text-align: center;
  background-color: $color-primary;
  color: $color-white;
  line-height: 30px;
}

.calendar .header .icon {
  cursor: pointer;
  transition: .15s ease-out;
}

.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: .25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  padding: .75em 0;
  flex-direction: row;
  display: flex;
  align-content: center;
  position: fixed;
  width: 430px;
  background: #fff;
  max-width: 100%;
  z-index: 99;
  top: 120px;
}

.calendar .body .cell {
  position: relative;
  height: 5em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
  border-image-slice: 1;
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .row:last-child {
  border-bottom: none;
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: .75em;
  right: .75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -.2em;
  right: -.05em;
  transition: .25s ease-out;
  letter-spacing: -.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: .5s ease-in;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100%/7);
  width: calc(100%/7);
}

.CalendarDays {
  padding: 0 3.5rem;
  margin: 1rem 0;
}

.calendarDate {
  padding: 0px;
  text-align: center;
  height: 40px;
  width: 40px !important;
  cursor: pointer;
  span {
    padding: 5px;
    display: block;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.currentDate {
    border: 1px solid $color-danger;
  }

  &.selected {
    border: 1px solid $color-danger;
    background-color: $color-danger;
    color: #fff;
  }
}

.currentMonthHeader {
  top: 150px;
  position: fixed;
  width: 430px;
  background: $color-primary;
}

.taskButton{
  text-align: center;
  width: 100%;
  padding: 10px 0
}
.allDays{
  font-size: 15px
}
.taskName{
  font-size: 17px
}
.listOfTask{
    display: flex;
}
