@import '../../styles/variables';

.wrapper {
    padding: 15px;
}

.docUL {
    list-style: none;
    padding: 0 10px;
    .docLists {
        margin: 10px 0;
        width: 100%;
        display: inline-block;
    }

    .docName {
        font-weight: bold;
        font-size: 16px;
        color: $color-primary;
        width: 100%;
        display: inline-block;
        font-family: "Avenir";
    }

    span.docChkBox {
        float: left;
        width: 10%;
        text-align: center;

        .form-check-input {
            margin-top: 5px;

            &:focus {
                box-shadow: 0;
            }
        }
    }

    div {
        float: left;
        width: 80%;
    }
}

.faqMainTitle {
    padding: 20px 0;
    background-color: #F1F1F1;
}

.faqBody {
    padding: 5px 15px !important;
}

.pageContent {
    iframe {
        width: 100%;
    }

    .febLocations {
        margin: 10px 0;

        li {
            margin: 10px 0;
        }
    }

    a {
        color: $color-danger;
    }
}

.gf_fields {
    label {
        width: 100%;
        display: inline-block;
        padding: 10px 0;
    }
    .requiredElement,.invalidElement {
        display: none;
    }
    .formControl {
        border: 1px solid #777;
        border-radius: 50px;
        padding: 10px;
        height: 50px;
        width: 100%;
        box-shadow: 1px 2px 10px #ddd;
        background: #fff;
    }
    select.formControl {
        padding: 10px 20px;
    }
    &.required {
        .formControl {
            border: 1px solid red;
        }
        .requiredElement {
            display: block;
            font-size: 12px;
            color: red;
            padding: 0 10px;
            margin: 0px;
        }
    }
    &.invalid {
        .formControl {
            border: 1px solid red;
        }
        .invalidElement {
            display: block;
            font-size: 12px;
            color: red;
            padding: 0 10px;
            margin: 0px;
        }
    }
}


.referalSubmitButton {
    button {
        margin: 20px 0;
    }
}

.referalThank {
    top:50%;
    padding: 50px;
    text-align: center;
    h3 {
        margin: 30px 0;
    }
}
