@import './../styles/variables';


.stickyHeader {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 999;
}

.playerPadding {
    padding-top: 20px;
    padding-bottom: 30px;
}

.playerClose {
    color: #fff;
    top: 30px;
    font-size: 20px;
    right: 15px;
    font-weight: bold;
    cursor: pointer;
    z-index: 999;
    filter: drop-shadow(30px 10px 4px #4444dd);
}

.videoWrapper {
    position: relative;
    /*padding-bottom: 56.25%;*/
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 999;

    .videoIframe {
        width: 100%;
        height: 90%;
        position: relative;

        iframe, video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

.videoContainer {

    .scrollContent {
        padding: 0 10%;
    }

    .selectElement {
        background: #fff;
        width: 100%;
        padding: 10px;
        border: 1px solid #000;
    }

    .videoTitle {
        font-size: 16px;
        color: #707070;
        width: 100%;
        display: inline-block;
        font-weight: bold;
    }

    .videoDesc {
        font-size: 12px;
        color: #707070;
        width: 100%;
        display: inline-block;
    }

    .videoDate {
        font-size: 12px;
        color: #707070;
        width: 100%;
        display: inline-block;
    }

    .videoSection {
        h3 {
            font-size: 24px;
            color: $color-primary;
            font-weight: bold;
            margin: 40px 0 15px;

            &:nth-of-type(1) {
                margin-top: 10px;
            }
        }

        ul {
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;

            li {
                flex-grow: 1;
                display: flex;
                flex-direction: row;
                margin-bottom: 30px;
                cursor: pointer;
                padding: 0 2%;

                &:last-child {
                    margin-bottom: 10px;
                }

                div {
                    margin-right: 15px;
                }

                .videoImage {
                    width: 69px;
                    height: 91px;
                    padding: 30px;
                    position: relative;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    border: 1px solid #707070;
                    border-radius: 4px;
                    cursor: pointer;

                    .largePlayButton {
                        margin: auto;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                    }
                }
            }
        }

        .highLightNewVideo {
            background: rgba($color-danger,.25);
            padding: 2%;
            border-radius: 5px;
        }
    }

    .infoSessionHeader {
        font-weight: 600;
        color: $color-primary;
        font-size: 18px;
        padding: 40px;

        p {
            margin-top: 10px;
            font-weight: normal;
            font-size: 16px;
        }

        ul {
            li {
                font-weight: normal;
                font-size: 16px;
            }
        }

        .confirmTalentSection {
            text-align: center;
            width: 100%;
            margin: 20px 0;
            line-height: 50px;

            a {
                margin: 0 10px;
                color: #fff;
                padding: 10px 40px;
                font-size: 18px;
                text-decoration: none;
                border-radius: 50px;
                background: $color-danger;
            }
        }
    }

    .infoSessionButton {
        text-align: center;
        margin: 20px 0;
        line-height: 40px;

        button, .roundedBackgroundButton {
            margin: 0 auto;
            max-width: 50%;
            background: $color-primary;
            border-radius: 50px;
            color: #fff;
            padding: 8px 20px;
            font-size: 16px;
            text-decoration: none;
        }
    }

    .completedVideoButton {
        text-align: center;

        button {
            margin: 0 auto;
            max-width: 50%;
            background: $color-danger;
            border-radius: 50px;
            color: #fff;
            padding: 8px 20px;
            font-size: 16px;
        }
    }
}



.notifyModalContainer {
    background-color: rgba($color-black, 0.5);
    z-index: 1010;
}

.notifyModal {
    min-height: 170px;
    background-color: #fff;
    left: 20px;
    right: 20px;
    border-radius: 10px;
    border: 0;

    .modalHeader {
        background-color: #353d56;
        padding: 15px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #fff;
        font-size: 22px;
        text-align: center;
        font-weight: bold;
    }

    .modalDetails {
        padding: 10px;
        font-size: 20px;
        text-align: center;
    }

    .modalFooter {
        text-align: center;
        padding: 5px 5px 10px 5px;

        .footerbutton {
            position: relative;
            margin: 10px;
            width: auto;
            padding: 5px 15px;
            background-color: $color-danger !important;
            color: #fff;
            font-size: 20px;
            border: 0px;
            border-radius: 10px;
            text-decoration: none;
        }

        a.footerbutton {
            padding: 8px 15px;
        }
    }
}
