@import '../../styles/variables';

.pageTitleSection {
    border-bottom: 1px solid transparent;
    background: $color-primary;
    box-shadow: 0 4px 6px -6px $color-black;
    padding: 50px 0 20px 0;

    h1 {
        font-size: 1.5rem;
    }

    .closeIcon {
        right: 20px;
        top: 20%;
        color: $color-white;
        font-size: 20px;
    }
}

.mindContact {
    padding-left: 20px;
    padding-right: 20px;
    color: $color-primary;
}

.textView {
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    white-space: pre-wrap
}

.mindView {
    font-size: 18px;
    width: 100%;
    margin-bottom: 0;

    input[type=radio] {
        position: relative;
        top: 2px;
    }
}

.buttonIcon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: $color-white;
    position: absolute;
    bottom: 3px;
    right: 0;
}

.button{
    background: $color-danger;
    border-radius: 20px;
    height: 35px;
    color: $color-white;
    font-size: 16px;
    border: 0;
    top: auto;
    left: 125px;
    margin-top: 20px;
    transform: translateX(-50%);
}

.scheduleButton {
    background: $color-primary;
    border-radius: 20px;
    width: 35%;
    height: 35px;
    color: $color-white;
    border: 0;
    margin-left: 32px;

    a {
        color: $color-white !important;
        text-decoration: none;

        &:hover {
            color: $color-white  !important;
            text-decoration: underline;
        }
    }
}


.TravelDonor_formElement label {
    color: #565656;
    font-family: avenirLtdBook;
    font-size: 1em;

    margin: 0 1em;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

input[type=checkbox],
input[type=radio] {
    appearance: none;
    padding: 0;
    margin: 0 .75em 0 0;
    border: 1px #da7058 solid;
    width: 1.125em;
    height: 1.125em;
    border-radius: .15em;
    position: relative;
    top: .05em;
    background-color: #fff;
    color: #565656;
}

input[type=checkbox]:checked,
input[type=radio]:checked {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpolyline fill='none' stroke='%23565656' stroke-width='3' stroke-linecap='round' stroke-line-join='round' stroke-miterlimit='10' points='1.87,9.64 6.53,16.25 16.13,1.75 '/%3E%3C/svg%3E%0A");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 80% 80%;
}

.psychModalContainer {
    background-color: rgba($color-black, 0.5);
    z-index: 1010;
}

.psychModal {
    min-height: 170px;
    background-color: #fff;
    left: 20px;
    right: 20px;
    border-radius: 10px;
    border: 0;

    .modalHeader {
        background-color: #353d56;
        padding: 15px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #fff;
        font-size: 22px;
        text-align: center;
        font-weight: bold;
    }

    .modalDetails {
        padding: 10px;
        font-size: 20px;
        text-align: center;
    }

    .modalFooter {
        text-align: center;
        padding: 5px 5px 10px 5px;

        .footerbutton {
            position: relative;
            margin: 10px;
            width: auto;
            padding: 8px 20px;
            background-color: $color-danger !important;
            color: #fff;
            font-size: 20px;
            border: 0px;
            border-radius: 10px;
        }
    }
}
