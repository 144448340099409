@import './../../styles/variables';

.content {
    background-color: $color-white;
    flex-grow: 1;
    padding-bottom: 5rem; // approx footer height - 80px
    /*overflow-y: auto;*/
    display: flex;
    flex-direction: column;
    height: 100%;
}
