@import '../../styles/variables';
@import "../../styles/common-imports";

.footer {
    position: fixed;
    width: 100%;
    max-width: 100%;/*480px;*/
    bottom: 0;
    background-color: $color-white;
    padding: 0;
    border-top: 1px solid $color-footer-border;
    filter: drop-shadow(0 1px 8px $color-footer-border);
    z-index: 999;
    bottom: 0; left: 0; width: 100%;
    height: 79px;

    ul {
        list-style: none;
        padding: 0 10%;
        // margin: 0;

        li {
            position: relative;
            width: 62px;
            height: auto;
            z-index: 1;            

            .activePT {
                display: none;
            }
            .inActivePT{
                display: block;
            }

            a{
                &.trackerActive {
                    .activePT {
                        display: block;
                    }
                    .inActivePT{
                        display: none;
                    }
                }
            }

            :global {
                a {
                    position: relative;
                    padding: 12px 0;
                    line-height: 50px;
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    height: 100%;
                    transition: top .3s ease, padding-top .3s ease;
                    overflow: hidden;

                    &::before,
                    &::after {
                        position: absolute;
                        content: "";
                        width: 60px;
                        left: 0;
                    }

                    &::before {
                        top: 0;
                        height: $menu-height;
                        background: $color-white;
                        border-radius: 100%;
                        border: 1px solid $color-white;
                    }

                    &::after {
                        background-color: $color-white;
                        height: calc($menu-height - $vertical-offset + 2px);
                    }

                    svg {
                        position: relative;
                        z-index: 10;
                        fill: $color-primary;
                        transition: fill .3s ease, transform .3s ease, top .3s ease;
                    }

                    &.active {
                        top: -$vertical-offset;
                        width: calc($menu-width + 2px);
                        text-align: center;
                        padding-top: $vertical-offset;
                        overflow: inherit;

                        &::before {
                            border-color: $color-footer-border;
                            /*box-shadow: -13px -23px 22px -20px $color-footer-border;*/
                        }

                        &::after {
                            top: $vertical-offset;
                        }

                        svg {
                            fill: $color-danger;
                            transform: scale(1.2);
                            top: 3px;

                            &.activePT{
                                display: block;
                            }

                            &.inActivePT {
                                display: none !important;
                            }
                        } 
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    footer { position: absolute !important; } 
}
